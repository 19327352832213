
/*  ALL PROJECTS PAGE */
  #projfilterbar {background: #0773b3; color: #ffffff; padding: 0; margin-bottom: 30px; }
  #projfilterbar .filterwrap {display: flex; justify-content: center; align-items: flex-start; flex-wrap: wrap;}
  #projfilterbar .filtercat1 {display: inline-block; text-align: center; cursor: pointer;padding: 27px 35px; box-sizing: border-box; transition: all 0.3s;}
  #projfilterbar .filtercat1.active {background: #b01532;}
  #projfilterbar .filtercat1:hover {background: #b01532;}
  #projfilterbar .imagewrapper {display: none; }
  #projfilterbar .titlewrap {font-size: calc(14pt + .2vw); text-transform: uppercase; letter-spacing: 1px; }


  #allprojects {padding: 0;}
  #allprojects .inner {max-width: 100%; padding: 0 2%; box-sizing: border-box; display: flex; justify-content: flex-start; flex-wrap: wrap;}
  #allprojects .projitem {width: 30.83%; margin: 0 1.25% 50px; text-align: center; transition: all 0.8s;}
  // #allprojects .projitem:nth-child(3n+3) {margin-right: 0;}
  #allprojects .projitem .imagewrapper {overflow: hidden;}
  #allprojects .projitem img {transition: all 0.3s;}
  #allprojects .projitem a:hover img {transform: scale3d(1.1,1.1,1);}
  #allprojects .projitem a .titlewrap {color: #040404; margin-top: 12px; font-size: calc(14pt + .25vw); font-weight: 400; line-height: 1.3em; text-transform: uppercase; }
  #allprojects .projitem a .subtitlewrap {color: #0773b3; font-size: calc(12pt + .1vw);  font-weight: 400; line-height: 1.2em; letter-spacing: 1.2px; text-transform: uppercase; }

  #allprojects .projitem.nodisplay {transform: scale(0.0); font-size: 0px !important; line-height: 0 !important; height: 0;opacity: 0; width: 0px; margin: 0; padding: 0; }
  #allprojects .projitem.absolute {opacity: 0; width: 0px; margin: 0;}

  #allprojects .projitem.outofpricerange {transform: scale(0.0);}
  #allprojects .projitem.absolute2 {opacity: 0; width: 0px; margin: 0;}

/* SINGLE PROJECTS PAGE */
  #content.singleproject {padding: 4.4vw 0;}
  #content.singleproject .inner {max-width: 100%; padding: 0; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap; }
  #content.singleproject .inner .leftside {width: 45%; padding: 20px 74px; box-sizing: border-box; }
  #content.singleproject .inner .leftside .item {color: #0773b3; font-size: calc(16pt + .1vw); font-weight: 400; margin-bottom: 14px; }
  #content.singleproject .inner .leftside .item span {color: #445761; font-weight: 700; margin-right: 5px; letter-spacing: -.8px; }
  #content.singleproject .inner .leftside .textwrap {margin-top: 40px; }
  #content.singleproject .inner .leftside .textwrap p {color: #040404; line-height: 2.1em; }
  #content.singleproject .inner .rightside {width: 53%; }

  



/* Single Project Bottom Gallery */
.project-gallery-wrapper {background: #42a2d6;}

#project-top-gallery {background: transparent; padding: 30px 0 20px;}
#project-top-gallery .inner {position: relative; }
#project-top-gallery .cg-slideshow {position: relative; margin: 0 auto; max-height: 75vh; width: auto;}
#project-top-gallery .cg-slideshow .slide-element {text-align: center;}
#project-top-gallery .cg-slideshow .slide-element img {vertical-align: top; max-height: 75vh; width: auto;}
#project-top-gallery .cycle-next {width: 50px; height: 50px; position: absolute; top: 50%; right: 0vw; cursor: pointer; margin-top: -25px; display: flex; justify-content: space-around; align-items: center;}
#project-top-gallery .cycle-prev {width: 50px; height: 50px; position: absolute; top: 50%; left: 0vw; cursor: pointer; margin-top: -25px; display: flex; justify-content: space-around; align-items: center;}

#project-top-gallery .cycle-next:before {color: #ffffff; content: "\f345"; font-size: 115px; font-family: "dashicons"; display: inline-block; opacity: 1; position: relative; text-shadow: 0 0 4px #000000; }
#project-top-gallery .cycle-prev:before {color: #ffffff; content: "\f341"; font-size: 115px; font-family: "dashicons"; display: inline-block; opacity: 1; position: relative; text-shadow: 0 0 4px #000000; }
#project-top-gallery .cycle-next:hover:before {color: #b01532; }
#project-top-gallery .cycle-prev:hover:before {color: #b01532; }

#project-bottom-gallery { padding: 0px 0 30px; }
#project-bottom-gallery .js-gallery {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
#project-bottom-gallery .js-gallery .element.image {display: inline-block; width: 16%; margin: 0 .8% .8% 0;position: relative; line-height: 0px;}
#project-bottom-gallery .js-gallery .element.image:nth-child(6n+6) {margin-right: 0;} 
#project-bottom-gallery .js-gallery .element.image .overlay {background: rgba(0,0,0,.75); position: absolute; top: 0; left: 0; width: 100%; height: 100%; line-height: 0; display: flex; justify-content: space-around; align-items: center; transition: all 0.5s; opacity: 0;}
#project-bottom-gallery .js-gallery .element.image .overlay:hover {opacity: 1;}
#project-bottom-gallery .js-gallery .element.image .overlay:before {color: #ffffff; content: "\f179"; font-family: "dashicons"; font-size: calc(16pt + 3vw); }


  // #projbottom .inner {text-align: center;}
  // #projbottom a.button.more-link {display: inline-block; margin: 0 auto; width: initial;}


@media only screen and (max-width: 1300px) {
  #projfilterbar .filtercat1 {width: 20%; }
}

@media only screen and (max-width: 1100px) {
  #allprojects .projitem {width: 46%; margin: 0 2% 50px;}
}

@media only screen and (max-width: 1000px) {
  #project-top-gallery .cycle-next:before {font-size: 85px;}
  #project-top-gallery .cycle-prev:before {font-size: 85px;}  

  #project-bottom-gallery .js-gallery .element.image:nth-child(6n+6) {margin-right: 1.25%;} 
  #project-bottom-gallery .js-gallery .element.image {width: 19%; margin: 0 1.25% 1.25% 0;}
  #project-bottom-gallery .js-gallery .element.image:nth-child(5n+5) {margin-right: 0;}

  #content.singleproject .inner {justify-content: center;}
  #content.singleproject .inner .leftside {order: 2;}
  #content.singleproject .inner .leftside, #content.singleproject .inner .rightside {width: 100%;}
}

@media only screen and (max-width: 950px) {
#project-top-gallery .cg-slideshow {position: relative; margin: 0 auto; max-height: 50vw; width: auto;}
#project-top-gallery .cg-slideshow .slide-element img {vertical-align: top; max-height: 50vw; width: auto;}

}

@media only screen and (max-width: 800px) {
  #project-bottom-gallery .js-gallery .element.image:nth-child(5n+5) {margin-right: 1.33%;}   
  #project-bottom-gallery .js-gallery .element.image:nth-child(6n+6) {margin-right: 1.33%;} 
  #project-bottom-gallery .js-gallery .element.image {width: 24%; margin: 0 1.33% 1.33% 0;}
  #project-bottom-gallery .js-gallery .element.image:nth-child(4n+4) {margin-right: 0;}   
}

@media only screen and (max-width: 700px) {
  #projfilterbar .filtercat1 {width: 33.33%; }

  #allprojects .projitem:nth-child(3n+3),
  #allprojects .projitem {width: 100%; max-width: 600px; margin: 0 auto 50px;}
  #allprojects .projitem.absolute {position: absolute;}

}

@media only screen and (max-width: 500px) {
  #projfilterbar .filtercat1 {width: 100%; }

  #project-top-gallery .cycle-next:before {font-size: 50px;}
  #project-top-gallery .cycle-prev:before {font-size: 50px;}  

  #content.singleproject .inner .leftside {padding: 20px;} 
}
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

/* ********************************* */
/*       Gallery Teaser Page         */
/* ********************************* */
.gallery-teaser-section .inner {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
.gallery-teaser-section .itembox .imagewrapper {overflow:hidden; transition: all 0.3s;}
.gallery-teaser-section .itembox .imagewrapper img  {transition: all 0.3s; width: 100%; height: auto;}
.gallery-teaser-section .itembox:hover .imagewrapper img {transform: scale3d(1.1, 1.1, 1.0);}
.gallery-teaser-section .itembox .title { padding: 10px 20px; font-size: 1.2em; text-align: center; box-sizing: border-box; width: 100%; transition: all 0.3s;}


/* ********************************* */
/*       Gallery Wrapper             */
/* ********************************* */
.gallery-wrapper {padding: 20px 0;background-color: #ffffff; background-attachment: fixed; background-size: cover;; background-position: center center; position: relative;}
.gallery-wrapper .overlay {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
.gallery-wrapper .itembox {margin-bottom: 30px;}
.gallery-wrapper .itembox:nth-last-of-type(1) {margin-bottom: 0;}



/* ********************************* */
/*       Gallery Slider              */
/* ********************************* */
#gallery-slider {background: transparent; padding: 0 2%;margin-bottom: 30px;}
#gallery-slider .inner {position: relative; }
#gallery-slider .gallery-slideshow {position: relative; margin: 0 auto; max-height: 75vh; width: auto;}
#gallery-slider .gallery-slideshow .slide-element {text-align: center;}
#gallery-slider .gallery-slideshow .slide-element img {vertical-align: top; max-height: 75vh; width: auto;}
#gallery-slider .cycle-next {width: 50px; height: 100px; position: absolute; top: 50%; right: 2.2%; cursor: pointer; margin-top: -25px; display: flex; justify-content: space-around; align-items: center;}
#gallery-slider .cycle-prev {width: 50px; height: 100px; position: absolute; top: 50%; left: 2.2%; cursor: pointer; margin-top: -25px; display: flex; justify-content: space-around; align-items: center;}

#gallery-slider .cycle-next:before {content: "\f345"; font-size: 115px; font-family: "dashicons"; display: inline-block; opacity: 1; position: relative; text-shadow: 2px 2px 4px rgba(0,0,0,.4); transition: all 0.3s; position: relative; left: -65%;}
#gallery-slider .cycle-prev:before {content: "\f341"; font-size: 115px; font-family: "dashicons"; display: inline-block; opacity: 1; position: relative; text-shadow: 2px 2px 4px rgba(0,0,0,.4); transition: all 0.3s;position: relative; left: -65%;}



/* ********************************* */
/*       Gallery Squares             */
/* ********************************* */
.gallerybox { padding: 0; }
.gallerybox .inner {padding-bottom: 0;}
.gallerybox .js-gallery {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
.gallerybox .js-gallery .itembox  {position: relative; line-height: 0px;}

.gallerybox .js-gallery .itembox .overlay {background: rgba(0,0,0,.75); position: absolute; top: 0; left: 0; width: 100%; height: 100%; line-height: 0; display: flex; justify-content: space-around; align-items: center; transition: all 0.5s; opacity: 0;}
.gallerybox .js-gallery .itembox .overlay:hover {opacity: 1;}
.gallerybox .js-gallery .itembox .overlay:before {color: #ffffff; content: "\f179"; font-family: "dashicons"; font-size: calc(16pt + 3vw); }

.gallerybox .js-gallery.masonary .itembox {margin-bottom: 0;}


/* ********************************* */
/*       Gallery Masonary             */
/* ********************************* */

//  *****  NOTE: Some CSS is deployed through the Header.php file.  Mainly Colors, and some margins

.gallerybox .js-gallery .imagewrapper.masonary  {position: relative; line-height: 0px; box-sizing: border-box;}
.gallerybox .js-gallery .imagewrapper.masonary .overlay {background: rgba(0,0,0,.75); position: absolute; top: 0; left: 0; width: 100%; height: 100%; line-height: 0; display: flex; justify-content: space-around; align-items: center; transition: all 0.5s; opacity: 0;}
.gallerybox .js-gallery .imagewrapper.masonary .overlay:hover {opacity: 1;}
.gallerybox .js-gallery .imagewrapper.masonary .overlay:before {color: #ffffff; content: "\f179"; font-family: "dashicons"; font-size: calc(16pt + 3vw); }



.masonary .itembox.col-two {width:50%; margin-right:0; margin-bottom:30px; display:block;}
.masonary .itembox.col-three {width:33.33%; margin-right:0; margin-bottom:30px; display:block;}
.masonary .itembox.col-four {width:25%; margin-right:0; margin-bottom:30px; display:block;}
.masonary .itembox.col-five {width:20%; margin-right:0; margin-bottom:30px; display:block;}
.masonary .itembox.col-six {width:16.66%; margin-right:0; margin-bottom:30px; display:block;}



@media only screen and (max-width:1200px) {
	.masonary .itembox.col-six {width:20%;}	
}

@media only screen and (max-width:1024px) {
	.masonary .itembox.col-three {width:33.33%;}
	.masonary .itembox.col-four {width:25%;}
	.masonary .itembox.col-five {width:25%;}
	.masonary .itembox.col-six {width:25%;}
}



@media only screen and (max-width: 1000px) {
	.gallery-teaser-section .gallerybox:nth-child(3n+3) {margin-right: 2%;}
	.gallery-teaser-section .gallerybox {display: inline-block; width: 49%; margin: 0 2% 2% 0;}
	.gallery-teaser-section .gallerybox:nth-child(2n+2) {margin-right: 0;}
}


@media only screen and (max-width:850px) {
	.masonary .itembox.col-five {width:33.33%;}
	.masonary .itembox.col-six {width:33.33%;}
}


@media only screen and (max-width:800px) {
	#gallery-slider .cycle-next:before {font-size: 75px;}
	#gallery-slider .cycle-prev:before {font-size: 75px;}
}


@media only screen and (max-width:768px) {
	.masonary .itembox.col-four {width:50%;}
}


@media only screen and (max-width:640px) {
	.masonary .itembox.col-two {width:50%;}
	.masonary .itembox.col-three {width:50%;}
	.masonary .itembox.col-five {width:50%;}
	.masonary .itembox.col-six {width:50%;}

}

@media only screen and (max-width: 500px) {
	#gallery-slider  {padding: 0 0px; }

	.masonary .itembox.col-two {width:100%;}
	.masonary .itembox.col-three {width:100%;}
	.masonary .itembox.col-four {width:100%;}
	.masonary .itembox.col-five {width:100%;}
	.masonary .itembox.col-six {width:100%;}

	#gallery-squares .itembox.col-six {width: 50%;}
	#gallery-squares .itembox.col-five {width: 50%;}
	#gallery-squares .itembox.col-four {width: 50%;}
	#gallery-squares .itembox.col-three {width: 50%;}
	#gallery-squares .itembox.col-two {width: 50%;}
}

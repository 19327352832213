
 
/* ********************************* */
/*       Sponsors Tweaks            */
/* ********************************* */

/* Sponsor Page */
.allsponsors .inner {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
.allsponsors .itembox { display: inline-block; border: 1px solid #cccccc; padding: 10px; box-sizing: border-box; transition: all 0.3s;}
.allsponsors .imagewrapper img {width: 100%; height: auto;}

.allsponsors .titlebox {color: #445761; font-size: calc(12pt + .2vw);  text-align: center; min-height: 40px; display: flex; justify-content: space-around; align-items: center; width: 100%; margin-top: 10px;}
.allsponsors .itembox a .titlebox {transition: all 0.3s;}
.allsponsors .itembox a:hover .titlebox {color: #42a2d6;}


#carousel1 {padding: 20px 0 35px;}
#carousel1 .titlewrap {width: 100%; background: #181818; margin-bottom: 20px; display: flex; justify-content: space-around; align-items: center;}
#carousel1 .titlewrap h2 {color: #ffffff; font-size: calc(16pt + 1.2vw); display: inline-block; line-height: 1.2em; padding: 30px 0;}
#carousel1 .imagewrapper a {display: inline-block;}
#carousel1 .slideshow-container {overflow: hidden !important; width: 100%; margin: 0 auto; position: relative;}
#carousel1 .slideshow-container .slideshow {overflow: visible !important; width: 15%; margin: 0 auto; display: flex; align-items: flex-start;}
#carousel1 .slideshow-container .slideshow .cycle-carousel-wrap {display: flex; align-items: center;}
#carousel1 .slideshow-container .slideshow .cycle-carousel-wrap a {line-height: 0px;}
#carousel1 .slideshow-container .slideshow div {padding-left: 10px; padding-right: 10px;}
#carousel1 .buttonbox {text-align: center; margin-top: 20px;}
#carousel1 .buttonbox a.button.more-link {background: transparent; border: 1px solid #7f7f7f; color: #7f7f7f; padding: 19px calc(20px + 4vw);}
#carousel1 .buttonbox a.button.more-link:hover {background-color: #181818; border-color: #181818; color: #ffffff;}
.browser-ie-trident #carousel1 .slideshow-container .slideshow .cycle-carousel-wrap {display: block;}  
.browser-ie-trident #carousel1 .slideshow-container .slideshow .cycle-carousel-wrap img {width: 100%;} 


@media only screen and (max-width: 1200px) {
}


@media only screen and (max-width: 1000px) {
  #carousel1 .slideshow-container .slideshow {width: 20%;}

 
}


@media only screen and (max-width: 900px) {
}

@media only screen and (max-width: 800px) {
  #carousel1 .titlewrap h2 {text-align: center;}
  #carousel1 .slideshow-container .slideshow {width: 25%;}
}

@media only screen and (max-width: 700px) {
}

@media only screen and (max-width:600px) {
  #carousel1 .slideshow-container .slideshow {width: 33%;}
}

@media only screen and (max-width: 500px) {

}
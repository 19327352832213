
/* -------------- Blog Category Page - category.php file ------------------------------- */
.category-page .allitemswrap {display: flex; align-content:flex-start; justify-content: flex-start; flex-wrap: wrap;}
.category-page .itembox.teaser figure {border-width: 1px; border-style: solid; overflow:hidden; line-height: 0;}
.category-page .itembox.teaser img {width:100%; height:auto; transition:all 0.3s;}
.category-page .itembox.teaser:hover img {transform:scale3d(1.1,1.1,1); -webkit-transform:scale3d(1.1,1.1,1);}
.category-page .itembox.teaser h2,
.category-page .itembox.teaser h2.title {font-size:calc(16pt + .35vw); line-height:1.1em; margin-top:5px; margin-bottom:5px; text-transform: none;}
.category-page .itembox.teaser .date-date {font-size:.8em; font-style:italic; line-height:1em; margin: 5px 0; }
.category-page .itembox.teaser .textwrap { line-height:1.4em;}
.category-page .itembox.teaser a.button {text-align:center;white-space:nowrap; width: inherit; margin-top: 10px; padding: 17px 40px;}


/* -------------- Blog Category Page - pager style ------------------------------- */
.navigation.pagination {width: 100%; position: relative;padding: 1px 0; clear: both; margin-bottom: 20px; display: flex; justify-content: space-around; align-items: flex-start;}
.navigation.pagination h2 {display: none;}
.navigation.pagination .nav-links {position: relative; line-height: 0;}
.navigation.pagination .page-numbers {font-size: 18px;line-height: 1em;  position: relative; width: inherit; min-width: 20px; padding: 4px 11px 4px; margin: 0 3px; border-radius: 17px; border: 0;  display: inline-block; -webkit-transition: all 0.8s; transition: all 0.8s; position: relative; overflow: hidden; z-index: 100; text-align: center;}
.navigation.pagination .next {font-size: 18px;line-height: 1em; ; font-weight: normal; position: relative; width: inherit; padding: 4px 20px 4px 30px; border-radius: 0 17px 17px 0; border: 0;  display: inline-block; margin: 0 0 0 -12px;  -webkit-transition: all 0.8s; transition: all 0.8s; position: relative; overflow: hidden; z-index: 1;}
.navigation.pagination .next:after {content: ' '; width: 33px;height: 33px; display: inline-block; position: absolute; left: -17px; top: -3px; border-radius: 50%;}
.navigation.pagination .prev {font-size: 18px;line-height: 1em; font-weight: normal; position: relative; width: inherit; padding: 4px 30px 4px 20px; border-radius: 17px 0 0 17px; border: 0;  display: inline-block; margin: 0 -12px 0 0;  -webkit-transition: all 0.8s; transition: all 0.8s; position: relative; overflow: hidden; z-index: 1;}
.navigation.pagination .prev:after {content: ' '; width: 33px;height: 33px; display: inline-block; position: absolute; right: -17px; top: -3px; border-radius: 50%;}


/* -------------- Single Blog Post - single-post.php file ------------------------------- */
.single-post .inner h1.title {margin-bottom:3px;text-align:left;}
.single-post .inner .contentwrap {display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;}
.single-post .inner .post-meta {margin-bottom:0px; }
.single-post .inner .date-date {font-size:.8em;font-style:italic;color:#1d1d1d;}
.single-post .inner .textwrap {display: inline-block; width: 48%;}
.single-post .inner .contentwrap .imagewrapper {display: inline-block;}


@media only screen and (max-width:900px) {
	.single-post .inner .textwrap {width: 100%;order: 2;}
	.single-post .inner .imagewrapper {width: 100%; order: 1; margin-bottom: 20px;}  

}
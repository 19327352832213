/* ********************************* */
/*       FAQ Accordions              */
/* ********************************* */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

/* Accordion Base behavior */
.accordion-wrapper {margin: 20px 0 0 0;}
.accordion .accordion-title {border-color: transparent;  font-size: 1.2em; margin-bottom: 2px; padding: 18px; width: 100%; text-align: left; cursor: pointer; border: none; outline: none; line-height:1em; padding-right: 40px; position: relative; transition: 0.4s; box-sizing: border-box;}


.accordion .accordion-title:after {content: '\f140'; font-family:"dashicons";  font-size: 1.2em; float: right; margin-left: 5px; display: block; height: 20px; width: 25px; position: absolute; right: 10px; top: 0; bottom: 0; margin: auto 0;}
.accordion.active .accordion-title:after {content: '\f142'; font-family:"dashicons";}

.accordion .accordion-text {padding: 0 18px; max-height: 0; overflow: hidden; transition: 0.6s ease-in-out; opacity: 0; border: 1px solid rgba(221,221,221,0); box-sizing: border-box;}
.accordion .accordion-text.show {opacity: 1; max-height: 10000px;}
.accordion.active .accordion-text { border-top: 0px solid transparent; margin: -2px 0 10px;}

/* FAQ Section */  
.faqsection {margin-bottom: 20px;}
.faqsection .accordion-wrapper {margin-top: 0px;}


@media only screen and (max-width: 800px) {

}

@media only screen and (max-width:600px) {

}

@media only screen and (max-width: 500px) {

}
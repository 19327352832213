/*  -------------- Scrollbar theme ---------------------------------------------------  */

::-webkit-scrollbar              { /* 1 */ }
::-webkit-scrollbar-button       { /* 2 */ }
::-webkit-scrollbar-track        { /* 3 */ }
::-webkit-scrollbar-track-piece  { /* 4 */ }
::-webkit-scrollbar-thumb        { /* 5 */ }
::-webkit-scrollbar-corner       { /* 6 */ }
::-webkit-resizer                { /* 7 */ }

::-webkit-scrollbar {width: 10px; background: rgba(0,0,0,0);}
::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.5);}
::-webkit-scrollbar-thumb {border-radius: 2px;  -webkit-box-shadow: inset 0 0 2px #1c1c1c; background: #8899a8; }


/*  --------------  Column Style for all content types in core--------------------------  */

.itembox.col-one {width:100%;}

.itembox.col-two {width:48%; margin-right:4%; margin-bottom:30px; display:block;}
.itembox.col-two:nth-child(2n+2) {margin-right:0;}
.itembox.col-two:nth-child(2n+3) {clear:both;}

.itembox.col-three {width:31.33%; margin-right:3%; margin-bottom:30px; display:block;}
.itembox.col-three:nth-child(3n+3) {margin-right:0;}
.itembox.col-three:nth-child(3n+4) {clear:both;}

.itembox.col-four {width:22.75%; margin-right:3%; margin-bottom:30px; display:block;}
.itembox.col-four:nth-child(4n+4) {margin-right:0;}
.itembox.col-four:nth-child(4n+5) {clear:both;}

.itembox.col-five {width:18.4%; margin-right:2%; margin-bottom:30px; display:block;}
.itembox.col-five:nth-child(5n+5) {margin-right:0;}
.itembox.col-five:nth-child(5n+6) {clear:both;}

.itembox.col-six {width:15%; margin-right:2%; margin-bottom:30px; display:block;}
.itembox.col-six:nth-child(6n+6) {margin-right:0;}
.itembox.col-six:nth-child(6n+7) {clear:both;}


@media only screen and (max-width:1200px) {
	.itembox.col-six:nth-child(6n+6) {margin-right:2%;}
	.itembox.col-six:nth-child(6n+7) {clear:none;}
	.itembox.col-six {width:18.4%; margin-right:2%; margin-bottom:30px;}
	.itembox.col-six:nth-child(5n+5) {margin-right:0;}
	.itembox.col-six:nth-child(5n+6) {clear:both;}
}

@media only screen and (max-width:1024px) {
	.itembox.col-three:nth-child(3n+3) {margin-right:4%;}
	.itembox.col-three:nth-child(3n+4) {clear:none;}
	.itembox.col-three {width:48%; margin-right:4%; margin-bottom:30px;}
	.itembox.col-three:nth-child(2n+2) {margin-right:0;}
	.itembox.col-three:nth-child(2n+3) {clear:both;}

	.itembox.col-four:nth-child(4n+4) {margin-right:3%;}
	.itembox.col-four:nth-child(4n+5) {clear:none;}
	.itembox.col-four {width:31.33%; margin-right:3%; margin-bottom:30px;}
	.itembox.col-four:nth-child(3n+3) {margin-right:0;}
	.itembox.col-four:nth-child(3n+4) {clear:both;}		
	
	.itembox.col-five:nth-child(5n+5) {margin-right:3%;}
	.itembox.col-five:nth-child(5n+6) {clear:none;}
	.itembox.col-five {width:22.75%; margin-right:3%; margin-bottom:30px;}
	.itembox.col-five:nth-child(4n+4) {margin-right:0;}
	.itembox.col-five:nth-child(4n+5) {clear:both;}

	.itembox.col-six:nth-child(6n+6) {margin-right:3%;}
	.itembox.col-six:nth-child(5n+5) {margin-right:3%;}
	.itembox.col-six:nth-child(5n+6) {clear:none;}
	.itembox.col-six {width:22.75%; margin-right:3%; margin-bottom:30px; }
	.itembox.col-six:nth-child(4n+4) {margin-right:0;}
	.itembox.col-six:nth-child(4n+5) {clear:both;}
}


@media only screen and (max-width:850px) {
	.itembox.col-five:nth-child(4n+4) {margin-right:3%;}
	.itembox.col-five:nth-child(4n+5) {clear:none;}
	.itembox.col-five {width:31.33%; margin-right:3%; margin-bottom:30px;}
	.itembox.col-five:nth-child(3n+3) {margin-right:0;}
	.itembox.col-five:nth-child(3n+4) {clear:both;}
	
	.itembox.col-six:nth-child(4n+4) {margin-right:3%;}
	.itembox.col-six:nth-child(4n+5) {clear:none;}
	.itembox.col-six {width:31.33%; margin-right:3%; margin-bottom:30px; }
	.itembox.col-six:nth-child(3n+3) {margin-right:0;}
	.itembox.col-six:nth-child(3n+4) {clear:both;}
}

@media only screen and (max-width:768px) {
	.itembox.col-four:nth-child(3n+3) {margin-right:4%;}
	.itembox.col-four:nth-child(3n+4) {clear:none;}
	.itembox.col-four {width:48%; margin-right:4%; margin-bottom:30px;}
	.itembox.col-four:nth-child(2n+2) {margin-right:0;}
	.itembox.col-four:nth-child(2n+3) {clear:both;}	
}

@media only screen and (max-width:640px) {
	.itembox.col-two {width:100%; margin-right:0%; margin-bottom:30px; clear:both; }
	.itembox.col-three {width:100%; margin-right:0%; margin-bottom:30px; clear:both; }
	.itembox.col-three:nth-child(3n+3) {margin-right:0%;}

	.itembox.col-five:nth-child(5n+5) {margin-right:4%;}
	.itembox.col-five:nth-child(3n+3) {margin-right:4%;}
	.itembox.col-five:nth-child(3n+4) {clear:none;}
	.itembox.col-five {width:48%; margin-right:4%; margin-bottom:30px; }
	.itembox.col-five:nth-child(2n+2) {margin-right:0;}
	.itembox.col-five:nth-child(2n+3) {clear:both;}	
	
	.itembox.col-six:nth-child(5n+5) {margin-right:4%;}
	.itembox.col-six:nth-child(3n+3) {margin-right:4%;}
	.itembox.col-six:nth-child(3n+4) {clear:none;}
	.itembox.col-six {width:48%; margin-right:4%; margin-bottom:30px; }
	.itembox.col-six:nth-child(2n+2) {margin-right:0;}
	.itembox.col-six:nth-child(2n+3) {clear:both;}
}

@media only screen and (max-width:400px) {
	.itembox.col-four {width:100%; margin-right:0%; margin-bottom:30px; clear:both; }
	.itembox.col-five {width:100%; margin-right:0%; margin-bottom:30px; clear:both; }
	.itembox.col-six {width:100%; margin-right:0%; margin-bottom:30px; clear:both; }
}






/* Starting banner css */
.minislideshow {position: relative;line-height: 0;}
.minislideshow.slideshow .itembox:nth-child(1) {position: relative; }
.minislideshow.slideshow .itembox {position: absolute; top: 0; left: 0; z-index: 1; opacity: 0; transition: all 1s; margin-bottom: 0px !important;  } 
.minislideshow.slideshow .itembox.inactive {opacity: 0; z-index: 2;} /* this makes the transition between slides smoother */
.minislideshow.slideshow .itembox.active {opacity: 1; z-index: 3;}
.minislideshow .slidebox {line-height: 0;}
.minislideshow .overlay {position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: space-around; align-items: center; overflow:hidden;}
.minislideshow .centerbox {display: inline-block; padding: 20px; box-sizing: border-box; text-align: left; line-height: 1.1em;}
.minislideshow .titlewrap {color: #185297; font-size: calc(16pt + 2vw); line-height: 1.1em; font-weight: 700;}
.minislideshow .subtitlewrap {color: #242424; font-size: calc(12pt + .4vw); margin: 10px 0 0 0; line-height: 1.1em;}
.minislideshow .buttonwrap {margin: 22px 0 0 0;z-index: 10; line-height: 1.1em;}

.minislideshow .overlay.nothingtoshow {display: none;}


.minislideshow.tall .overlay {justify-content: flex-start; max-width: calc(1600px - 4%); margin: 0 auto; right: 0; padding: 0; }
.minislideshow.tall .overlay .centerbox {padding: 0; max-width: 35%;}

/*  animate overlay box items  */
.minislideshow .itembox.active.starting .overlay.animateoverlay-no .centerbox {opacity: 1;} /* this makes sure when not animating the first slide when first starting doesnt just POP in place */
.minislideshow .itembox.active.starting .overlay.animateoverlay-yes .centerbox {opacity: 0; transform: translateY(100vh);} /* this helps the first slide when first starting the slideshow to animate correct */
.minislideshow .itembox .overlay.animateoverlay-yes .centerbox {opacity: 0; transform: translateY(100vh);}
.minislideshow .itembox.active .overlay.animateoverlay-yes .centerbox {transition-delay: .5s; transition: all 1s; transform: translateY(0); opacity: 1;}
.minislideshow .itembox.inactive .overlay.animateoverlay-yes .centerbox {transform: translateY(0); opacity: 0;}


.minislideshow .slideshowdots {position: absolute; width: 100%; bottom: 10px; z-index: 300; display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap; }
.minislideshow .slideshowdots .centerbox {padding: 0; display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap;}
.minislideshow .slideshowdots .dot {display: inline-block; background: rgba(255,255,255, .6); border: 2px solid #ffffff; width: 15px; height: 15px; margin: 0 5px; border-radius: 50%; transition: all 0.3s;  box-sizing: border-box;}
.minislideshow .slideshowdots .dot.active {background-color: rgba(255,255,255, 1);}
.minislideshow .slideshowdots .dot:hover {border-color: #000000;  }
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

#alltestimonials {background: transparent;  }
#alltestimonials .inner {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
#alltestimonials .itembox { display: inline-block; box-sizing: border-box; position: relative; z-index: 50;}

#alltestimonials .testimonial { border-radius: 20px; padding: 20px 20px 30px 20px; position: relative; }
#alltestimonials .testimonial {font-style: italic; font-size: 1.0em;}
#alltestimonials .testimonial:after {content: " "; display: block; width: 40px; height: 40px; transform: rotate(45deg); position: absolute; bottom: -20px; left: 0; right: 0; margin: 0 auto; z-index: 1;}

#alltestimonials .testimonial .stars {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
#alltestimonials .testimonial .stars .iconbox:after {content: "\f155"; font-family: "dashicons"; font-size: 40px;  display: inline-block; font-style: normal;}


#alltestimonials .itembox img {width: 100%; height: auto; }
#alltestimonials .itembox .name { font-weight: 700; margin-top: 40px; width: 100%; text-align: center; }
#alltestimonials .itembox .subtitle { margin-top: 10px; width: 100%; text-align: center; }


@media only screen and (max-width: 1000px) {
  
}
@media only screen and (max-width: 700px) {
}

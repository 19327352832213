/* ********************************* */
/*       VIDEO Tweaks            */
/* ********************************* */

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
.videotitle .inner {padding: 0 2%;}
.videosection .inner {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap; padding-top: 0; }

.videosection .itembox a {width: 100%;}
.videosection .itembox a:after {display: none; width: 100%;}
.videosection .imagewrapper {position: relative; overflow: hidden; width: 100%;}

.videosection .imagewrapper:after {content: "\f522"; font-family: "dashicons"; color: rgba(255,255,255,.85); text-shadow: 2px 2px 4px rgba(0,0,0,.7); font-size: calc(50px + 5vw); position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex;  justify-content: space-around; align-items: center;	}
.videosection .imagewrapper img {width: 100%; height: auto;}

.videosection .title {display: block; text-align: center; font-size: calc(14pt + .4vw); line-height: 1.2em; margin-top: 7px; transition: all 0.3s;}




@media only screen and (max-width: 1000px) {

}

@media only screen and (max-width:600px) {

}

@media only screen and (max-width: 500px) {
}
.js_left_right_slider {background: #ffffff; border: 2px solid #ffffff; padding: calc(20px + 2vw) 0 calc(20px + 2vw) 0; box-sizing: border-box; position: relative; display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap;}
.js_left_right_slider .overflow_hidden_box {background: transparent;  overflow: hidden; position: relative;}
.js_left_right_slider .inset_wrapper {} // this wrapper is here to obey the flex rules above. so the next container can Overflow and not get munged on by Flex
.js_left_right_slider .hold_vertical_open { overflow-x: visible; width: 100000px; opacity: 0;}

.js_left_right_slider .itembox {width:900px; display: inline-block;} 
.js_left_right_slider .slider_box {position: absolute; top: 0; left: 0; width: 100%; height: 100%;}
.js_left_right_slider .slider_box .itembox {position: absolute; top: 50%; left: 50%; ;} //the -10px is for HALF of the height of the dots navigation

.js_left_right_slider .overlay {position: absolute; }
.js_left_right_slider .dot_nav {display:none;}
.js_left_right_slider .arrow_nav {display: none;}


/* Slider Width Controls */
.js_left_right_slider.one {width: 1200px; box-sizing: border-box; margin: 0 auto;} //start with PX widths, and switch to VW widths in responsive media query
.js_left_right_slider.one .itembox {width: 1200px; padding: 20px 70px; box-sizing: border-box; }  //start with PX widths, and switch to VW widths in responsive media query

/* Enable Arrow Navigation */
// .js_left_right_slider.one .arrow_nav {top: 50%; height: 80px; width: 100%; display: flex; justify-content: space-between; align-items: stretch; transform: translateY(-50%);z-index: 20;}
// .js_left_right_slider.one .arrow {width: 60px; height: 100%;display: flex; justify-content: space-around; align-items: center; overflow: hidden; transition: all 0.3s;}
// .js_left_right_slider.one .prev {margin-left: 20px; padding-left: 0px; box-sizing: border-box;} //adjust this as needed so it centers in the box correctly
// .js_left_right_slider.one .prev:before {color: #e00e2d; content: "\f141"; font-size: 150px; font-family: "dashicons"; opacity: 1; text-shadow: 0 0 4px #000000; line-height: 1em; transition: all 0.3s;cursor: pointer;}
// .js_left_right_slider.one .prev:hover:before {color: #1b1b1b;}
// .js_left_right_slider.one .next {margin-right:20px; padding-right: 10px; box-sizing: border-box;} //adjust this as needed so it centers in the box correctly
// .js_left_right_slider.one .next:before {color: #e00e2d; content: "\f139"; font-size: 150px; font-family: "dashicons"; opacity: 1; text-shadow: 0 0 4px #000000; line-height: 1em; transition: all 0.3s;cursor: pointer;}
// .js_left_right_slider.one .next:hover:before {color: #1b1b1b;}

/* Enable Dots Navigation */
.js_left_right_slider.one {padding-bottom: calc(20px + 2vw + 30px);} //add the HEIGHT of the .dot_nav bar to the slider bottom padding.
.js_left_right_slider.one .slider_box .itembox {top: calc(50% - 15px); } //adjust top calc to half of the HEIGHT on the .dot_nav bar
.js_left_right_slider.one .dot_nav {bottom: 0; height: 30px; width: 100%; margin-top: 0; display: flex; justify-content: space-around; align-items: center; z-index: 20;line-height: 0;}
.js_left_right_slider.one .dot_nav .centerbox {margin-top: 3px;line-height: 0; display: flex; justify-content: flex-start; align-items: center;}
.js_left_right_slider.one .dot_nav .dot {background: rgba(0,0,0,.5); width: 15px; height: 15px; margin: 0 3px; border-radius: 50%; display: inline-block; transition: all 0.3s; cursor: pointer;}
.js_left_right_slider.one .dot_nav .dot.active {background-color: #e00e2d;}
.js_left_right_slider.one .dot_nav .dot:hover {background-color: #e00e2d;}

/* Move Dots Outside of background box. */
.js_left_right_slider.one {background: transparent; border: 2px solid transparent; }
.js_left_right_slider.one .overflow_hidden_box {background: white;} 
.js_left_right_slider.one .dot_nav { position: relative; margin-top: 20px;;}
.js_left_right_slider.one .slider_box .itembox {top: calc(50%); }


/* animate slides */
.js_left_right_slider.one .slider_box .itembox {transform: translateX(100vw) translateY(-50%); opacity: 0; transition: all 2s; z-index: 1; display: block; } //stack all the slides off to the right. and make them transparent.
.js_left_right_slider.one .slider_box .itembox.step1 {z-index: 2; opacity: .3;} // prestage the next slide coming into view.  set its Z-index so it comes to the top of the stack.  start making it transparent;
.js_left_right_slider.one .slider_box .itembox.step2 {z-index: 3; opacity: 1; transform: translateX(-50%) translateY(-50%);} //slide the Active slide into view.  Increment its Zindex, and make it solid.
.js_left_right_slider.one .slider_box .itembox.step3 {z-index: 2; opacity: .3; transform: translateX(calc(-100vw - 50%)) translateY(-50%);} // animate the slide off the left of the screen, decrase its Z index and get it ready to go back into the stack.
.js_left_right_slider.one .slider_box .itembox.step4 {z-index: 1; opacity: 0; transform: translateX(100vw) translateY(-50%); display: none;} // now that its transparent and on the left side, we can now move it back to the right size stack.

/* Make the Infor Look Pretty */
.js_left_right_slider.one .itembox .titlewrap  {font-size: calc(16pt + .7vw); color: #000000; font-weight: 700; line-height:1.1em; margin: 0 0 28px; text-transform: none; letter-spacing: 2px;}
.js_left_right_slider.one .itembox .textwrap p {line-height: 1.8em; }
.js_left_right_slider.one .itembox .visit_line {color: #000000; font-size: calc(14pt + .3vw); font-weight: 700; margin-top: 28px; text-align: right; letter-spacing: 0px; white-space: pre-wrap; }

/* DEVELOPER COLOR DISPLAYS */
// .js_left_right_slider .dot_nav {background: rgba(0,200,0,.2);}
// .js_left_right_slider .arrow_nav {background: rgba(200,0,0,.2);}
// .js_left_right_slider .arrow {background: rgba(0,0,200,.2);}


@media only screen and (max-width: 1280px) {
	/* Slider Width Controls */
	.js_left_right_slider.one {width: 93.75vw; box-sizing: border-box; margin: 0 auto;} //start with PX widths, and switch to VW widths in responsive media query
	.js_left_right_slider.one .itembox {width: 93.75vw; padding: 20px 70px; box-sizing: border-box; }  //start with PX widths, and switch to VW widths in responsive media query

	

}
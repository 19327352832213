/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
#content.landing-page .inner {padding: 40px 2% 40px; display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;}
#content.landing-page .inner .textwrap {width: 100%;}
#content.landing-page .leftside {width: 48%; display: inline-block;}
#content.landing-page .rightside {width: 48%; display: inline-block;}
#content.landing-page .rightside .imagewrapper {border: 1px solid rgba(222,222,222,0); box-sizing: border-box; overflow: hidden;}
#content.landing-page .rightside .imagewrapper img {width: 100%; height: auto;}

#content.landing-page .inner.flex {display: flex;}

#topcallouts .inner {padding: 0px 2% 0px; display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap;}
#topcallouts .titlewrap h2 {font-size: calc(16pt + .2vw); font-weight: 600; text-align: center; transition: all 0.3s;}
#topcallouts .imagewrapper {border-width: 2px; border-style: solid; transition: all 0.3s; overflow: hidden;}
#topcallouts .imagewrapper img {transition: all 0.3s;}
#topcallouts .itembox:hover .imagewrapper img {transform: scale3d(1.1, 1.1, 1.0);}


.lpcolumns {width: 100%; padding-top: 0; padding-bottom: 0; display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap; box-sizing: border-box;}


//  ****************************************************************************************
//  *****  NOTE: Column Responsive CSS is in the _base.scss file.
//  ****************************************************************************************

#stagger {margin-bottom: 30px;}
#stagger .itembox {display: flex; justify-content: space-between; align-items: stretch; flex-wrap: wrap; line-height: 0px;}
#stagger .itembox .leftside {order: 2;  display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap; box-sizing: border-box;}
#stagger .itembox .rightside {order: 3; display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap; box-sizing: border-box;}
#stagger .stagger-part:nth-child(even) .rightside {order: 1;}
#stagger .stagger-part {position: relative; background-attachment: fixed; background-size: cover; background-repeat: no-repeat;}
#stagger .stagger-part .stagger-overlaycolor {position: absolute; width: 100%; top: 0; right: 0; height: 100%; z-index: 0;}
#stagger .stagger-part .inner {position: relative; z-index: 1;}

#stagger.split7030 .leftside {width: 70%;}
#stagger.split7030 .rightside {width: 30%;}

#stagger.split6040 .leftside {width: 60%;}
#stagger.split6040 .rightside {width: 40%;}

#stagger.split5050 .leftside {width: 50%;}
#stagger.split5050 .rightside {width: 50%;}

#stagger.split4060 .leftside {width: 40%;}
#stagger.split4060 .rightside {width: 60%;}

#stagger.split3070 .leftside {width: 30%;}
#stagger.split3070 .rightside {width: 70%;}


#lpbottom {width: 100%; display: block; margin-bottom: 30px;}
#lpbottom .inner {padding-top: 0; padding-bottom: 0;}


@media only screen and (max-width: 1100px) {
	#stagger.split7030 .leftside {width: 100%;}
	#stagger.split7030 .rightside {width: 100%;}
	#stagger.split7030 .itembox .rightside {padding: 20px 0;}
	#stagger.split7030 .stagger-part:nth-child(even) .rightside {order: 3;}	
}

@media only screen and (max-width: 1000px) {
	#stagger.split6040 .leftside {width: 100%;}
	#stagger.split6040 .rightside {width: 100%;}
	#stagger.split6040 .itembox .rightside {padding: 20px 0;}
	#stagger.split6040 .stagger-part:nth-child(even) .rightside {order: 3;}
}

@media only screen and (max-width: 900px) {
	#content.landing-page .leftside {width: 100%; order: 2;}
	#content.landing-page .rightside {width: 100%; order: 1;}	

	#stagger.split3070 .leftside {width: 100%;}
	#stagger.split3070 .rightside {width: 100%;}
	#stagger.split3070 .itembox .rightside {padding: 20px 0;}
	#stagger.split3070 .stagger-part:nth-child(even) .rightside {order: 3;}
}


@media only screen and (max-width: 800px) {
	#stagger.split4060 .leftside {width: 100%;}
	#stagger.split4060 .rightside {width: 100%;}
	#stagger.split4060 .itembox .rightside {padding: 20px 0;}
	#stagger.split4060 .stagger-part:nth-child(even) .rightside {order: 3;}

	#stagger.split5050 .leftside {width: 100%;}
	#stagger.split5050 .rightside {width: 100%;}
	#stagger.split5050 .itembox .rightside {padding: 20px 0;}
	#stagger.split5050 .stagger-part:nth-child(even) .rightside {order: 3;}
}

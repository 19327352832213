/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

/*======================= Staff Category Page ==================================*/
.staffcat .inner .staffwrapper {display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap; }
.staffcat .inner .staffwrapper .itembox {  line-height: 0; display: flex; justify-content: space-around; align-items: flex-start; flex-wrap:wrap; transition: all .5s; }
.staffcat .inner .staffwrapper .itembox .imagewrapper {width: 100%; display: block; }
.staffcat .inner .staffwrapper .itembox .imagewrapper img {width: 100%; transition: all 0.3s;}

.staffcat .inner .staffwrapper .itembox.biolink {cursor: pointer; transition: all 0.3s;}
.staffcat .inner .staffwrapper .itembox.biolink:hover {box-shadow: 4px 4px 8px rgba(0,0,0,.8)}
.staffcat .inner .staffwrapper .itembox.biolink .imagewrapper {overflow: hidden;}
.staffcat .inner .staffwrapper .itembox.biolink a {width: 100%;}
.staffcat .inner .staffwrapper .itembox.biolink a:hover .imagewrapper img {transform: scale3d(1.1, 1.1, 1.0);}

.staffcat .inner .staffwrapper .itembox .centerbox { padding: 10px 10px; box-sizing: border-box; display: flex; justify-content: space-around; flex-wrap: wrap;}
.staffcat .inner .staffwrapper .itembox .centerbox .title  {position: relative; margin: 0 0 10px 0; font-size: calc(14pt + .2vw); font-weight: 700; transition: all .5s; line-height: 1.2em; text-align: center; text-transform: uppercase;  width: 100%;}
.staffcat .inner .staffwrapper .itembox .centerbox .position {line-height: 1em; transition: all 0.3s; width: 100%; text-align: center;}
.staffcat .inner .staffwrapper .itembox .centerbox .email { width: 100%; text-align: center;margin-top: 7px;}
.staffcat .inner .staffwrapper .itembox .centerbox .email a {line-height: 1em;  transition: all 0.3s;}


.single-staff-page {margin: 40px 0 30px;}
.single-staff-page .inner {position: relative; display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;}
.single-staff-page .leftside {width: 38%; display: inline-block; }
.single-staff-page .rightside {width: 58%; display: inline-block; }





@media only screen and (max-width: 600px) {
  .single-staff-page .leftside {width: 100%;}
  .single-staff-page .leftside .imagewrapper {max-width: 400px; margin: 0 auto;}
  .single-staff-page .rightside {width: 100%; margin-top: 30px;}
}
  


@media only screen and (max-width: 500px) {

}

/* ********************************* */
/*       Documents Tweaks            */
/* ********************************* */

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
.documentstitle .inner {padding: 0 2%;}

.alldocuments {margin-bottom: 20px;}
.alldocuments .inner {display: flex; justify-content: flex-start; align-items: flex-start; flex-wrap: wrap; padding-top: 0;}
// .alldocuments .itembox:nth-child(odd) {background: rgba(245,245,245,0);}
.alldocuments .itembox.header-row {background: #173b56; color: #ffffff; font-size: calc(14pt + .3vw); font-weight: normal; padding: 10px; border-color: #173b56; line-height: 1.1em; box-sizing: border-box;}
.alldocuments .itembox.header-row h2 {color: #ffffff}


.alldocuments .itembox {padding: 2px 10px; margin-bottom: 0; display: flex; justify-content: space-between; align-items: center; box-sizing: border-box; flex-wrap: wrap;}
.alldocuments .itembox .doc-type {width: 80px; padding: 5px 10px; display: flex; justify-content: space-around; align-items: center; box-sizing: border-box;}
.alldocuments .itembox .doc-title {width: calc(100% - 80px - 120px - 185px); padding: 5px 10px;  display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; box-sizing: border-box;}
.alldocuments .itembox .doc-size {text-align: center; width: 120px; padding: 5px 10px;  display: flex; justify-content: space-around; align-items: center; box-sizing: border-box;}
.alldocuments .itembox .doc-link {text-align: center; width: 185px; padding: 5px 10px;  display: flex; justify-content: space-around; align-items: center; box-sizing: border-box;}
.alldocuments .itembox .doc-link .iconbox {display: none;}

.alldocuments .itembox .doc-type .icon {width: 32px; min-height: 32px; padding-top: 4px;  text-align: center;}
.alldocuments .itembox .doc-type .icon:before {content: "\f190"; color: #a32324; font-size: 32px; font-family: "dashicons";display: inline-block; margin-top: 5px;}
.alldocuments .itembox .doc-title { font-size: 1.1em; line-height: 1.2em;}

.alldocuments .itembox.docbutton {padding: 2px 0; margin-bottom: 4px;}
.alldocuments .itembox.docbutton a.button {width: 100%; height: 100%; padding: 5px 10px; display: flex; justify-content: flex-start; align-items: center;}
.alldocuments .itembox.docbutton .doc-title {width: 100%; padding: 0; font-size: .8em; display: inline-block; text-transform: none;}
.alldocuments .itembox.docbutton .iconbox {width: 40px; padding: 0; margin: 0; display: flex; justify-content: space-around; align-items: center; box-sizing: border-box;}
.alldocuments .itembox.docbutton .iconbox.one {margin-right: 20px;}
.alldocuments .itembox.docbutton .iconbox.two {margin-left: 20px;}

.alldocuments .itembox .iconbox.one .icon:before {content: "\f190";  font-size: 32px; font-family: "dashicons";display: inline-block; margin-top: 5px;}
.alldocuments .itembox .iconbox.two .icon:before {content: "\f316";  font-size: 32px; font-family: "dashicons";display: inline-block; margin-top: 5px; font-weight: 300;}



@media only screen and (max-width: 800px) {
  .alldocuments .itembox .doc-title {width: calc(100% - 80px - 185px);}
  .alldocuments .itembox .doc-size {display: none;}
}

@media only screen and (max-width:600px) {
  .alldocuments .itembox .doc-title {width: calc(100% - 150px); font-size: .85em;}
  .alldocuments .itembox .doc-type {display: none;}
  .alldocuments .itembox .doc-link  {width: 150px; font-size: .85em;}
  .alldocuments .itembox .doc-link  span {display: none;} 
  // .alldocuments .itembox a.button {padding: 14px 20px;} 
}

@media only screen and (max-width: 500px) {
  .alldocuments .itembox .doc-title {width: calc(100% - 80px);}
  .alldocuments .itembox .doc-link {width: 80px;}
  .alldocuments .itembox .doc-link .button {display: none;}
  .alldocuments .itembox .doc-title { font-size: .95em; }
  .alldocuments .itembox .doc-link .iconbox {height: 45px; width: 45px; position: relative; display: inline-block;}
  .alldocuments .itembox .doc-link .iconbox:before {  content: "\f316";  font-family: "dashicons"; background-color: transparent; font-size: 40px; display: block; line-height: 1em; transition: all 0.3s;}
  // .alldocuments .itembox .doc-link .iconbox:hover:before {color: #445761;}  

  .alldocuments .itembox.header-row .doc-link .textwrapper {display: none;}
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/**
 * Core specific resets and layout that generally won't be touched, hence why it is "hidden" here
 */
html {
  overflow-x: hidden;
  overflow-y: scroll; }

html a:focus {
  outline: none; }

a, a:active, a:hover {
  outline: none;
  text-decoration: none;
  cursor: pointer; }

ol {
  list-style-type: decimal;
  padding-left: 40px;
  margin: 1em 0; }

ul {
  list-style-type: disc;
  padding-left: 40px;
  margin: 1em 0; }

li {
  line-height: 1.3em; }

figure {
  padding: 0;
  line-height: 0; }

.screen-reader-response {
  display: none !important; }

/*  -------------- Scrollbar theme ---------------------------------------------------  */
::-webkit-scrollbar {
  /* 1 */ }

::-webkit-scrollbar-button {
  /* 2 */ }

::-webkit-scrollbar-track {
  /* 3 */ }

::-webkit-scrollbar-track-piece {
  /* 4 */ }

::-webkit-scrollbar-thumb {
  /* 5 */ }

::-webkit-scrollbar-corner {
  /* 6 */ }

::-webkit-resizer {
  /* 7 */ }

::-webkit-scrollbar {
  width: 10px;
  background: rgba(0, 0, 0, 0); }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); }

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 2px #1c1c1c;
  background: #8899a8; }

/*  --------------  Column Style for all content types in core--------------------------  */
.itembox.col-one {
  width: 100%; }

.itembox.col-two {
  width: 48%;
  margin-right: 4%;
  margin-bottom: 30px;
  display: block; }

.itembox.col-two:nth-child(2n+2) {
  margin-right: 0; }

.itembox.col-two:nth-child(2n+3) {
  clear: both; }

.itembox.col-three {
  width: 31.33%;
  margin-right: 3%;
  margin-bottom: 30px;
  display: block; }

.itembox.col-three:nth-child(3n+3) {
  margin-right: 0; }

.itembox.col-three:nth-child(3n+4) {
  clear: both; }

.itembox.col-four {
  width: 22.75%;
  margin-right: 3%;
  margin-bottom: 30px;
  display: block; }

.itembox.col-four:nth-child(4n+4) {
  margin-right: 0; }

.itembox.col-four:nth-child(4n+5) {
  clear: both; }

.itembox.col-five {
  width: 18.4%;
  margin-right: 2%;
  margin-bottom: 30px;
  display: block; }

.itembox.col-five:nth-child(5n+5) {
  margin-right: 0; }

.itembox.col-five:nth-child(5n+6) {
  clear: both; }

.itembox.col-six {
  width: 15%;
  margin-right: 2%;
  margin-bottom: 30px;
  display: block; }

.itembox.col-six:nth-child(6n+6) {
  margin-right: 0; }

.itembox.col-six:nth-child(6n+7) {
  clear: both; }

@media only screen and (max-width: 1200px) {
  .itembox.col-six:nth-child(6n+6) {
    margin-right: 2%; }
  .itembox.col-six:nth-child(6n+7) {
    clear: none; }
  .itembox.col-six {
    width: 18.4%;
    margin-right: 2%;
    margin-bottom: 30px; }
  .itembox.col-six:nth-child(5n+5) {
    margin-right: 0; }
  .itembox.col-six:nth-child(5n+6) {
    clear: both; } }

@media only screen and (max-width: 1024px) {
  .itembox.col-three:nth-child(3n+3) {
    margin-right: 4%; }
  .itembox.col-three:nth-child(3n+4) {
    clear: none; }
  .itembox.col-three {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 30px; }
  .itembox.col-three:nth-child(2n+2) {
    margin-right: 0; }
  .itembox.col-three:nth-child(2n+3) {
    clear: both; }
  .itembox.col-four:nth-child(4n+4) {
    margin-right: 3%; }
  .itembox.col-four:nth-child(4n+5) {
    clear: none; }
  .itembox.col-four {
    width: 31.33%;
    margin-right: 3%;
    margin-bottom: 30px; }
  .itembox.col-four:nth-child(3n+3) {
    margin-right: 0; }
  .itembox.col-four:nth-child(3n+4) {
    clear: both; }
  .itembox.col-five:nth-child(5n+5) {
    margin-right: 3%; }
  .itembox.col-five:nth-child(5n+6) {
    clear: none; }
  .itembox.col-five {
    width: 22.75%;
    margin-right: 3%;
    margin-bottom: 30px; }
  .itembox.col-five:nth-child(4n+4) {
    margin-right: 0; }
  .itembox.col-five:nth-child(4n+5) {
    clear: both; }
  .itembox.col-six:nth-child(6n+6) {
    margin-right: 3%; }
  .itembox.col-six:nth-child(5n+5) {
    margin-right: 3%; }
  .itembox.col-six:nth-child(5n+6) {
    clear: none; }
  .itembox.col-six {
    width: 22.75%;
    margin-right: 3%;
    margin-bottom: 30px; }
  .itembox.col-six:nth-child(4n+4) {
    margin-right: 0; }
  .itembox.col-six:nth-child(4n+5) {
    clear: both; } }

@media only screen and (max-width: 850px) {
  .itembox.col-five:nth-child(4n+4) {
    margin-right: 3%; }
  .itembox.col-five:nth-child(4n+5) {
    clear: none; }
  .itembox.col-five {
    width: 31.33%;
    margin-right: 3%;
    margin-bottom: 30px; }
  .itembox.col-five:nth-child(3n+3) {
    margin-right: 0; }
  .itembox.col-five:nth-child(3n+4) {
    clear: both; }
  .itembox.col-six:nth-child(4n+4) {
    margin-right: 3%; }
  .itembox.col-six:nth-child(4n+5) {
    clear: none; }
  .itembox.col-six {
    width: 31.33%;
    margin-right: 3%;
    margin-bottom: 30px; }
  .itembox.col-six:nth-child(3n+3) {
    margin-right: 0; }
  .itembox.col-six:nth-child(3n+4) {
    clear: both; } }

@media only screen and (max-width: 768px) {
  .itembox.col-four:nth-child(3n+3) {
    margin-right: 4%; }
  .itembox.col-four:nth-child(3n+4) {
    clear: none; }
  .itembox.col-four {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 30px; }
  .itembox.col-four:nth-child(2n+2) {
    margin-right: 0; }
  .itembox.col-four:nth-child(2n+3) {
    clear: both; } }

@media only screen and (max-width: 640px) {
  .itembox.col-two {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 30px;
    clear: both; }
  .itembox.col-three {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 30px;
    clear: both; }
  .itembox.col-three:nth-child(3n+3) {
    margin-right: 0%; }
  .itembox.col-five:nth-child(5n+5) {
    margin-right: 4%; }
  .itembox.col-five:nth-child(3n+3) {
    margin-right: 4%; }
  .itembox.col-five:nth-child(3n+4) {
    clear: none; }
  .itembox.col-five {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 30px; }
  .itembox.col-five:nth-child(2n+2) {
    margin-right: 0; }
  .itembox.col-five:nth-child(2n+3) {
    clear: both; }
  .itembox.col-six:nth-child(5n+5) {
    margin-right: 4%; }
  .itembox.col-six:nth-child(3n+3) {
    margin-right: 4%; }
  .itembox.col-six:nth-child(3n+4) {
    clear: none; }
  .itembox.col-six {
    width: 48%;
    margin-right: 4%;
    margin-bottom: 30px; }
  .itembox.col-six:nth-child(2n+2) {
    margin-right: 0; }
  .itembox.col-six:nth-child(2n+3) {
    clear: both; } }

@media only screen and (max-width: 400px) {
  .itembox.col-four {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 30px;
    clear: both; }
  .itembox.col-five {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 30px;
    clear: both; }
  .itembox.col-six {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 30px;
    clear: both; } }

body {
  background: white;
  color: #1b1b1b; }

h1, h1 a {
  color: #b01532; }

h2, h2 a {
  color: #0773b3; }

h3, h3 a {
  color: #0773b3; }

h4, h4 a {
  color: #0773b3; }

h5, h5 a {
  color: #0773b3; }

h6, h6 a {
  color: #0773b3; }

a, a:link, a:visited {
  color: #0773b3; }

a:hover, a:focus {
  color: #2a302f; }

.form-item input.wpcf7-submit, .mce-content-body a.button[data-mce-selected], .af-submit button.acf-button, button.af-submit-button, a.button {
  background: #b01532;
  color: #ffffff;
  border-color: #b01532; }

.form-item input.wpcf7-submit:hover, .mce-content-body a.button[data-mce-selected]:hover, .af-submit button.acf-button:hover, button.af-submit-button:hover, a.button:hover {
  background: #445761;
  color: #ffffff;
  border-color: #445761; }

.category-page .itembox.teaser figure {
  border-color: #818285; }

.category-page .itembox.teaser .date-date {
  color: #1d1d1d; }

.single-post .inner .date-date {
  color: #1d1d1d; }

.navigation.pagination .next:after, .navigation.pagination .prev:after {
  background: white; }

.navigation.pagination .page-numbers, navigation.pagination .next, navigation.pagination .prev {
  background: #445761;
  color: #ffffff; }

.navigation.pagination .page-numbers.current, .navigation.pagination .page-numbers:hover, .navigation.pagination .next:hover, .navigation.pagination .prev:hover {
  background-color: #0773b3;
  color: #ffffff; }

.js-back-to-top {
  background: #445761;
  color: #ffffff;
  border-color: #ffffff; }

.js-back-to-top:hover {
  background: #0773b3;
  color: #ffffff;
  border-color: #ffffff; }

#header1 {
  background: white; }

#header2 {
  background: white; }

#header3 {
  background: white; }

#footer1 {
  background: white; }

#footer2 {
  background: white; }

#footer3 {
  background: white; }

.social-wrapper .itembox a {
  background: #040707;
  color: #ffffff; }

.social-wrapper .itembox a:hover {
  background-color: #b01532;
  color: #ffffff; }

.widget_nav_menu .menu > .menu-item > a, .widget_nav_menu_top_row .menu .menu-item a {
  background: rgba(7, 115, 179, 0);
  color: #040404; }

.widget_nav_menu .menu > .menu-item.current-menu-parent > a, .widget_nav_menu_top_row .menu .menu-item.current-menu-parent a, .widget_nav_menu_top_row .menu .menu-item.current-menu-item a, .widget_nav_menu_top_row .menu .menu-item a:hover, .widget_nav_menu .menu > .menu-item > a:hover, .widget_nav_menu_top_row .menu .menu-item.current-menu-item a, .widget_nav_menu_top_row .menu .menu-item.current-menu-parent a {
  background: rgba(176, 21, 50, 0);
  color: #0773b3; }

.widget_nav_menu .menu .sub-menu > .menu-item > a {
  background: white;
  color: #040404; }

.widget_nav_menu .menu .sub-menu > .menu-item > a:hover, .widget_nav_menu .menu .sub-menu > .menu-item.current-menu-item > a {
  background: #b01532;
  color: #ffffff; }

.nav-mobile .menuicon:before {
  color: #1b1b1b; }

.nav-mobile.nav-mobile-open .menuicon:before {
  color: #0773b3; }

.widget_nav_sub_menu_part .menu .menu-item a {
  color: #0773b3; }

.widget_nav_sub_menu_part .menu .menu-item a:hover {
  color: #2a302f; }

.widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a, .widget_nav_menu .menu.nav-active > .menu-item.current-page-ancestor > a, .widget_nav_menu .menu.nav-active > .menu-item > a {
  background: white;
  color: #040404; }

.widget_nav_menu .menu.nav-active .sub-menu > .menu-item.current-menu-item > a, .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a:hover, .widget_nav_menu .menu.nav-active > .menu-item.current-menu-item > a, .widget_nav_menu .menu.nav-active > .menu-item > a:hover {
  background: #b01532;
  color: #ffffff; }

.accordion.wysiwyg .accordion-title {
  background: #0773b3;
  color: #ffffff;
  border-color: #0773b3; }

.accordion.wysiwyg .accordion-title:hover, .accordion.wysiwyg.active .accordion-title {
  background: #53c9f2;
  color: #ffffff;
  border-color: #53c9f2; }

.accordion.wysiwyg .accordion-title:hover:after {
  color: #ffffff; }

.accordion.wysiwyg.active .accordion-text {
  border-color: #53c9f2; }

.accordion.wysiwyg .accordion-title:after {
  color: #ffffff; }

.accordion.wysiwyg.active .accordion-title:after, .accordion.wysiwyg.active .accordion-title:hover:after {
  color: #ffffff; }

.search-form-widget {
  position: absolute;
  margin: 0;
  top: 0;
  right: 0;
  max-width: 200px; }

.search-results {
  margin: 0 0 30px; }

.search-result {
  margin: 30px 0 0 0;
  padding: 0 0 30px;
  border-bottom: 1px solid #e2e2e2; }
  .search-result img {
    display: none; }
  .search-result span.standout {
    background: black;
    color: white;
    display: inline-block;
    padding: 2px 15px;
    border-radius: 5px; }

/* ******************************************************************** */
/* ***  Widget - MENU Base CSS */
/* ******************************************************************** */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
.widget_nav_menu {
  width: 100%;
  z-index: 600; }

.widget_nav_menu > div {
  position: relative;
  z-index: 600;
  width: 100%;
  line-height: 0px; }

.widget_nav_menu .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  width: 100%;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
  margin: 0; }

.widget_nav_menu .menu .menu-item {
  margin: 0;
  padding: 0;
  line-height: 0px;
  display: inline-block;
  line-height: 1em; }

.widget_nav_menu .menu .menu-item a {
  display: block;
  white-space: nowrap;
  transition: all 0.3s; }

.widget_nav_menu .menu .menu-item .sub-menu {
  position: relative;
  display: none;
  min-width: 200px;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
  transition: all 0.3s;
  padding-left: 0; }

.widget_nav_menu .menu .menu-item:hover .sub-menu {
  display: block;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  padding-left: 0;
  border-bottom: 1px solid #000;
  padding-top: 21px; }

.widget_nav_menu .menu .menu-item .sub-menu .menu-item {
  display: block;
  position: relative;
  border-left: 1px solid #000;
  border-right: 1px solid #000; }

.widget_nav_menu .menu .menu-item .sub-menu:nth-of-type(2) {
  transform: translateY(100%) translateX(100%); }

/* this is needed in the case that they hide a top level page */
/* TOP LEVEL Menu Adjustments */
.widget_nav_menu .menu > .menu-item > a {
  font-size: 16px;
  padding: 10px 5px;
  font-weight: 500;
  letter-spacing: 1.8px;
  text-transform: capitalize;
  line-height: 1em; }

/* top level */
.overrideCSS .widget_nav_menu .menu > .menu-item > a:hover {
  background: #B01532;
  color: #ffffff; }

.overrideCSS .widget_nav_menu .menu #menu-item-617 > a {
  background: #0773B3;
  color: #ffffff; }

.overrideCSS .widget_nav_menu .menu #menu-item-617 > a:hover {
  background: #B01532;
  color: #ffffff; }

/* Sub Menu Level Adjustments */
.widget_nav_menu .menu .sub-menu > .menu-item > a {
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  padding: 10px 12px 10px;
  line-height: 1em; }

/* Sub Menu */
.nav-mobile {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0;
  height: 56px;
  width: 56px;
  font-size: 25px;
  margin-right: 0;
  z-index: 2000; }

/* Mobile Navigation */
.nav-mobile .menuicon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "dashicons"; }

.nav-mobile .menuicon:before {
  content: "\f349"; }

.widget_nav_menu .menu .menu-item .sub-menu .sub-menu {
  display: none; }

/* ******************************************************************** */
/* ***  Widget - MENU - Top Row Base CSS */
/* ******************************************************************** */
.widget_nav_menu_top_row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

.widget_nav_menu_top_row .centerbox {
  display: inline-block; }

.widget_nav_menu_top_row .menu {
  display: inline-block;
  list-style: none;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-padding-start: 0;
  margin: 0; }

.widget_nav_menu_top_row .menu .menu-item {
  margin: 0;
  padding: 0;
  line-height: 0px;
  display: inline-block;
  line-height: 1em; }

.widget_nav_menu_top_row .menu .menu-item a {
  font-size: 17px;
  padding: 10px 5px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1em;
  display: block; }

/* ******************************************************************** */
/* ***  Widget - MENU - Sub Menu Part CSS */
/* ******************************************************************** */
.widget_nav_sub_menu_part {
  display: inline-block; }

.widget_nav_sub_menu_part h2 {
  font-size: calc(12pt + .3vw);
  text-transform: capitalize; }

.widget_nav_sub_menu_part .menu {
  padding: 0;
  margin: 0; }

.widget_nav_sub_menu_part .menu .menu-item {
  list-style: none; }

.widget_nav_sub_menu_part .menu .menu-item a {
  transition: all 0.3s; }

@media only screen and (max-width: 1200px) {
  /* Mobile MENU tweaks NEW*/
  .widget_nav_menu {
    width: 100%; }
  .widget_nav_menu > div {
    height: 40px;
    padding: 0px 0;
    margin-top: 0px; }
  .widget_nav_menu .menu {
    display: none; }
  .widget_nav_menu .menu.nav-active {
    display: block;
    position: absolute;
    bottom: -19px;
    right: 0;
    transform: translateY(100%);
    border: 1px solid #000; }
  .widget_nav_menu .menu.nav-active .menu-item {
    display: block;
    margin-top: 0px; }
  .widget_nav_menu .menu.nav-active .menu-item .sub-menu {
    display: block;
    position: relative;
    transform: translateY(0);
    padding-top: 0;
    border-bottom: none; }
  .widget_nav_menu .menu .menu-item .sub-menu .menu-item {
    border: none; }
  /* TOP LEVEL Menu Adjustments */
  .overrideCSS .widget_nav_menu .menu > .menu-item.current-menu-parent > a,
  .widget_nav_menu .menu.nav-active > .menu-item > a {
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    padding: 8px 12px 8px;
    line-height: 1em; }
  /* top level */
  .overrideCSS .widget_nav_menu .menu #menu-item-617 > a {
    background-color: #ffffff;
    color: #040404; }
  /* Sub Menu Level Adjustments */
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a {
    font-size: 18px;
    font-weight: 400;
    text-transform: none;
    padding: 8px 34px 8px 34px;
    line-height: 1em; }
  /* Sub Menu */
  .widget_nav_menu .menu.nav-active .sub-menu .sub-menu .menu-item > a {
    padding: 8px 34px 8px 56px; }
  /* sub sub menu*/
  .nav-mobile {
    display: block;
    width: 40px;
    height: 40px;
    padding: 0; }
  /* 3 bar menu block */
  .nav-mobile .menuicon:before {
    position: relative;
    left: 0px; }
  .nav {
    width: 100%;
    height: 56px;
    margin-left: 0px; } }

/* Starting banner css */
.slideshow-banner-box {
  position: relative;
  line-height: 0; }

.slideshow-banner-box.slideshow .itembox:nth-child(1) {
  position: relative; }

.slideshow-banner-box.slideshow .itembox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: all 1s; }

.slideshow-banner-box.slideshow .itembox.inactive {
  opacity: 0;
  z-index: 2; }

/* this makes the transition between slides smoother */
.slideshow-banner-box.slideshow .itembox.active {
  opacity: 1;
  z-index: 3; }

.slideshow-banner-box .slidebox {
  line-height: 0; }

.is-homepage .slideshow-banner-box .slidebox .imagewrapper {
  border-radius: 6px; }

.slideshow-banner-box .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden; }

.slideshow-banner-box .centerbox {
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  line-height: 1.1em; }

.slideshow-banner-box .titlewrap {
  color: #ffffff;
  font-size: calc(16pt + 2vw);
  line-height: 1.1em; }

.slideshow-banner-box .subtitlewrap {
  color: #ffffff;
  font-size: calc(12pt + .5vw);
  margin: 10px 0 0 0;
  line-height: 1.1em; }

.slideshow-banner-box .buttonwrap {
  margin: 22px 0 0 0;
  z-index: 10;
  line-height: 1.1em; }

.slideshow-banner-box .overlay.nothingtoshow {
  display: none; }

/*  animate overlay box items  */
.slideshow-banner-box .itembox.active.starting .overlay.animateoverlay-no .centerbox {
  opacity: 1; }

/* this makes sure when not animating the first slide when first starting doesnt just POP in place */
.slideshow-banner-box .itembox.active.starting .overlay.animateoverlay-yes .centerbox {
  opacity: 0;
  transform: translateY(100vh); }

/* this helps the first slide when first starting the slideshow to animate correct */
.slideshow-banner-box .itembox .overlay.animateoverlay-yes .centerbox {
  opacity: 0;
  transform: translateY(100vh); }

.slideshow-banner-box .itembox.active .overlay.animateoverlay-yes .centerbox {
  transition-delay: .5s;
  transition: all 1s;
  transform: translateY(0);
  opacity: 1; }

.slideshow-banner-box .itembox.inactive .overlay.animateoverlay-yes .centerbox {
  transform: translateY(0);
  opacity: 0; }

.slideshow-banner-box .slideshowdots {
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 6;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

.slideshow-banner-box .slideshowdots .centerbox {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.slideshow-banner-box .slideshowdots .dot {
  display: inline-block;
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid #ffffff;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  transition: all 0.3s;
  box-sizing: border-box; }

.slideshow-banner-box .slideshowdots .dot.active {
  background-color: #ffffff; }

.slideshow-banner-box .slideshowdots .dot:hover {
  border-color: #000000; }

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
.social-wrapper .itemwrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.social-wrapper .itembox {
  display: inline-block;
  margin: 3px 5px 3px 0; }

.social-wrapper .itembox:nth-last-child(1) {
  margin-right: 0; }

.social-wrapper .itembox a {
  text-decoration: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.3s; }

.social-wrapper .icon {
  line-height: 0px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.social-wrapper .icon:after {
  font-family: "dashicons";
  font-size: calc(40px * 0.7); }

.social-wrapper .icon.twitter svg {
  transition: all 0.3s; }

.social-wrapper .icon.tiktok svg {
  transition: all 0.3s; }

.social-wrapper .icon.tiktok svg path.cls-1 {
  fill: #ffffff; }

/*  ------------------------------------------------------------------------------------  */
/*  -------------- TWITTER icon sizing note --------------------------------------------  */
/*  -- To change the icon size you need to alter the SVG size directly -----------------  */
/*  -- in the /inc/widgets/widget-social-icons.php file around line 61 -----------------  */
/*  ------------------------------------------------------------------------------------  */
.social-wrapper.round .itembox a {
  border-radius: 50%; }

.social-wrapper.square-rounded-corners .itembox a {
  border-radius: 5px; }

.social-wrapper.no-background .itembox a {
  background-color: transparent; }

.social-wrapper .icon.share:after {
  content: "\f237"; }

.social-wrapper .icon.rss:after {
  content: "\f303"; }

.social-wrapper .icon.email:after {
  content: "\f466"; }

.social-wrapper .icon.networking:after {
  content: "\f325"; }

.social-wrapper .icon.amazon:after {
  content: "\f162"; }

.social-wrapper .icon.facebook:after {
  content: "\f305"; }

.social-wrapper .icon.google:after {
  content: "\f18b"; }

.social-wrapper .icon.instagram:after {
  content: "\f12d"; }

.social-wrapper .icon.linkedin:after {
  content: "\f18d"; }

.social-wrapper .icon.pinterest:after {
  content: "\f192"; }

.social-wrapper .icon.podio:after {
  content: "\f19c"; }

.social-wrapper .icon.reddit:after {
  content: "\f195"; }

.social-wrapper .icon.spotify:after {
  content: "\f196"; }

.social-wrapper .icon.twitch:after {
  content: "\f199"; }

.social-wrapper .icon.whatsapp:after {
  content: "\f19a"; }

.social-wrapper .icon.youtube:after {
  content: "\f19b"; }

/* if a custom icon is needed.  You need to roll that icon into a FONT and then add a line like this followin gexample. */
/* .social-wrapper .icon.custom:after {content: "\e900"; font-family: "YourFontName";} */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
#content.landing-page .inner {
  padding: 40px 2% 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

#content.landing-page .inner .textwrap {
  width: 100%; }

#content.landing-page .leftside {
  width: 48%;
  display: inline-block; }

#content.landing-page .rightside {
  width: 48%;
  display: inline-block; }

#content.landing-page .rightside .imagewrapper {
  border: 1px solid rgba(222, 222, 222, 0);
  box-sizing: border-box;
  overflow: hidden; }

#content.landing-page .rightside .imagewrapper img {
  width: 100%;
  height: auto; }

#content.landing-page .inner.flex {
  display: flex; }

#topcallouts .inner {
  padding: 0px 2% 0px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

#topcallouts .titlewrap h2 {
  font-size: calc(16pt + .2vw);
  font-weight: 600;
  text-align: center;
  transition: all 0.3s; }

#topcallouts .imagewrapper {
  border-width: 2px;
  border-style: solid;
  transition: all 0.3s;
  overflow: hidden; }

#topcallouts .imagewrapper img {
  transition: all 0.3s; }

#topcallouts .itembox:hover .imagewrapper img {
  transform: scale3d(1.1, 1.1, 1); }

.lpcolumns {
  width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  box-sizing: border-box; }

#stagger {
  margin-bottom: 30px; }

#stagger .itembox {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  line-height: 0px; }

#stagger .itembox .leftside {
  order: 2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box; }

#stagger .itembox .rightside {
  order: 3;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box; }

#stagger .stagger-part:nth-child(even) .rightside {
  order: 1; }

#stagger .stagger-part {
  position: relative;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat; }

#stagger .stagger-part .stagger-overlaycolor {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 0; }

#stagger .stagger-part .inner {
  position: relative;
  z-index: 1; }

#stagger.split7030 .leftside {
  width: 70%; }

#stagger.split7030 .rightside {
  width: 30%; }

#stagger.split6040 .leftside {
  width: 60%; }

#stagger.split6040 .rightside {
  width: 40%; }

#stagger.split5050 .leftside {
  width: 50%; }

#stagger.split5050 .rightside {
  width: 50%; }

#stagger.split4060 .leftside {
  width: 40%; }

#stagger.split4060 .rightside {
  width: 60%; }

#stagger.split3070 .leftside {
  width: 30%; }

#stagger.split3070 .rightside {
  width: 70%; }

#lpbottom {
  width: 100%;
  display: block;
  margin-bottom: 30px; }

#lpbottom .inner {
  padding-top: 0;
  padding-bottom: 0; }

@media only screen and (max-width: 1100px) {
  #stagger.split7030 .leftside {
    width: 100%; }
  #stagger.split7030 .rightside {
    width: 100%; }
  #stagger.split7030 .itembox .rightside {
    padding: 20px 0; }
  #stagger.split7030 .stagger-part:nth-child(even) .rightside {
    order: 3; } }

@media only screen and (max-width: 1000px) {
  #stagger.split6040 .leftside {
    width: 100%; }
  #stagger.split6040 .rightside {
    width: 100%; }
  #stagger.split6040 .itembox .rightside {
    padding: 20px 0; }
  #stagger.split6040 .stagger-part:nth-child(even) .rightside {
    order: 3; } }

@media only screen and (max-width: 900px) {
  #content.landing-page .leftside {
    width: 100%;
    order: 2; }
  #content.landing-page .rightside {
    width: 100%;
    order: 1; }
  #stagger.split3070 .leftside {
    width: 100%; }
  #stagger.split3070 .rightside {
    width: 100%; }
  #stagger.split3070 .itembox .rightside {
    padding: 20px 0; }
  #stagger.split3070 .stagger-part:nth-child(even) .rightside {
    order: 3; } }

@media only screen and (max-width: 800px) {
  #stagger.split4060 .leftside {
    width: 100%; }
  #stagger.split4060 .rightside {
    width: 100%; }
  #stagger.split4060 .itembox .rightside {
    padding: 20px 0; }
  #stagger.split4060 .stagger-part:nth-child(even) .rightside {
    order: 3; }
  #stagger.split5050 .leftside {
    width: 100%; }
  #stagger.split5050 .rightside {
    width: 100%; }
  #stagger.split5050 .itembox .rightside {
    padding: 20px 0; }
  #stagger.split5050 .stagger-part:nth-child(even) .rightside {
    order: 3; } }

/* -------------- Blog Category Page - category.php file ------------------------------- */
.category-page .allitemswrap {
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap; }

.category-page .itembox.teaser figure {
  border-width: 1px;
  border-style: solid;
  overflow: hidden;
  line-height: 0; }

.category-page .itembox.teaser img {
  width: 100%;
  height: auto;
  transition: all 0.3s; }

.category-page .itembox.teaser:hover img {
  transform: scale3d(1.1, 1.1, 1);
  -webkit-transform: scale3d(1.1, 1.1, 1); }

.category-page .itembox.teaser h2,
.category-page .itembox.teaser h2.title {
  font-size: calc(16pt + .35vw);
  line-height: 1.1em;
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: none; }

.category-page .itembox.teaser .date-date {
  font-size: .8em;
  font-style: italic;
  line-height: 1em;
  margin: 5px 0; }

.category-page .itembox.teaser .textwrap {
  line-height: 1.4em; }

.category-page .itembox.teaser a.button {
  text-align: center;
  white-space: nowrap;
  width: inherit;
  margin-top: 10px;
  padding: 17px 40px; }

/* -------------- Blog Category Page - pager style ------------------------------- */
.navigation.pagination {
  width: 100%;
  position: relative;
  padding: 1px 0;
  clear: both;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start; }

.navigation.pagination h2 {
  display: none; }

.navigation.pagination .nav-links {
  position: relative;
  line-height: 0; }

.navigation.pagination .page-numbers {
  font-size: 18px;
  line-height: 1em;
  position: relative;
  width: inherit;
  min-width: 20px;
  padding: 4px 11px 4px;
  margin: 0 3px;
  border-radius: 17px;
  border: 0;
  display: inline-block;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  overflow: hidden;
  z-index: 100;
  text-align: center; }

.navigation.pagination .next {
  font-size: 18px;
  line-height: 1em;
  font-weight: normal;
  position: relative;
  width: inherit;
  padding: 4px 20px 4px 30px;
  border-radius: 0 17px 17px 0;
  border: 0;
  display: inline-block;
  margin: 0 0 0 -12px;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.navigation.pagination .next:after {
  content: ' ';
  width: 33px;
  height: 33px;
  display: inline-block;
  position: absolute;
  left: -17px;
  top: -3px;
  border-radius: 50%; }

.navigation.pagination .prev {
  font-size: 18px;
  line-height: 1em;
  font-weight: normal;
  position: relative;
  width: inherit;
  padding: 4px 30px 4px 20px;
  border-radius: 17px 0 0 17px;
  border: 0;
  display: inline-block;
  margin: 0 -12px 0 0;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  overflow: hidden;
  z-index: 1; }

.navigation.pagination .prev:after {
  content: ' ';
  width: 33px;
  height: 33px;
  display: inline-block;
  position: absolute;
  right: -17px;
  top: -3px;
  border-radius: 50%; }

/* -------------- Single Blog Post - single-post.php file ------------------------------- */
.single-post .inner h1.title {
  margin-bottom: 3px;
  text-align: left; }

.single-post .inner .contentwrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

.single-post .inner .post-meta {
  margin-bottom: 0px; }

.single-post .inner .date-date {
  font-size: .8em;
  font-style: italic;
  color: #1d1d1d; }

.single-post .inner .textwrap {
  display: inline-block;
  width: 48%; }

.single-post .inner .contentwrap .imagewrapper {
  display: inline-block; }

@media only screen and (max-width: 900px) {
  .single-post .inner .textwrap {
    width: 100%;
    order: 2; }
  .single-post .inner .imagewrapper {
    width: 100%;
    order: 1;
    margin-bottom: 20px; } }

/* EMPLOYMENT CSS */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
#allpositions {
  margin-bottom: 20px; }

.teaser-page .nth-rule-wrapper {
  width: 100%; }

/* this is a fix for IE11 */
.teaser-page .itembox {
  width: 100%;
  margin-bottom: 0;
  border: 1px solid #a19fa0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.teaser-page .itembox .col-one {
  width: 350px;
  padding: 10px;
  box-sizing: border-box;
  display: inline-block;
  border: 0; }

.teaser-page .itembox .col-one > div {
  line-height: 1.2em; }

.teaser-page .itembox .col-two {
  width: calc(100% - 350px);
  padding: 10px;
  box-sizing: border-box;
  display: inline-block;
  border: 0; }

.teaser-page .itembox label {
  font-weight: bold; }

.teaser-page .itembox label:after {
  content: ':';
  margin-right: 10px; }

.teaser-page .itembox .position-title {
  font-style: normal;
  font-size: calc(14pt + .28vw);
  font-weight: 700;
  margin-bottom: 20px; }

.teaser-page .nth-rule-wrapper .itembox {
  width: 100%;
  box-sizing: border-box; }

.teaser-page .itembox.header-row {
  background: #445761;
  border-color: #445761;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
  box-sizing: border-box;
  max-width: 100%;
  overflow: hidden; }

.teaser-page .itembox .col-two {
  border-left: 1px dashed #a19fa0; }

@media only screen and (max-width: 700px) {
  .teaser-page .itembox .position-title {
    font-size: calc(14pt + 1.0vw);
    margin-top: 10px; }
  .teaser-page .itembox.header-row .col-two {
    display: none; }
  .teaser-page .itembox .col-one {
    width: 100%;
    margin-bottom: 10px; }
  .teaser-page .itembox.header-row .col-one {
    margin-bottom: 0; }
  .teaser-page .itembox .col-two {
    width: 100%;
    border-left: 0;
    padding-top: 0;
    border-top: 1px dashed #e2e2e2; } }

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
/*======================= Staff Category Page ==================================*/
.staffcat .inner .staffwrapper {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

.staffcat .inner .staffwrapper .itembox {
  line-height: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  transition: all .5s; }

.staffcat .inner .staffwrapper .itembox .imagewrapper {
  width: 100%;
  display: block; }

.staffcat .inner .staffwrapper .itembox .imagewrapper img {
  width: 100%;
  transition: all 0.3s; }

.staffcat .inner .staffwrapper .itembox.biolink {
  cursor: pointer;
  transition: all 0.3s; }

.staffcat .inner .staffwrapper .itembox.biolink:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.8); }

.staffcat .inner .staffwrapper .itembox.biolink .imagewrapper {
  overflow: hidden; }

.staffcat .inner .staffwrapper .itembox.biolink a {
  width: 100%; }

.staffcat .inner .staffwrapper .itembox.biolink a:hover .imagewrapper img {
  transform: scale3d(1.1, 1.1, 1); }

.staffcat .inner .staffwrapper .itembox .centerbox {
  padding: 10px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; }

.staffcat .inner .staffwrapper .itembox .centerbox .title {
  position: relative;
  margin: 0 0 10px 0;
  font-size: calc(14pt + .2vw);
  font-weight: 700;
  transition: all .5s;
  line-height: 1.2em;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }

.staffcat .inner .staffwrapper .itembox .centerbox .position {
  line-height: 1em;
  transition: all 0.3s;
  width: 100%;
  text-align: center; }

.staffcat .inner .staffwrapper .itembox .centerbox .email {
  width: 100%;
  text-align: center;
  margin-top: 7px; }

.staffcat .inner .staffwrapper .itembox .centerbox .email a {
  line-height: 1em;
  transition: all 0.3s; }

.single-staff-page {
  margin: 40px 0 30px; }

.single-staff-page .inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; }

.single-staff-page .leftside {
  width: 38%;
  display: inline-block; }

.single-staff-page .rightside {
  width: 58%;
  display: inline-block; }

@media only screen and (max-width: 600px) {
  .single-staff-page .leftside {
    width: 100%; }
  .single-staff-page .leftside .imagewrapper {
    max-width: 400px;
    margin: 0 auto; }
  .single-staff-page .rightside {
    width: 100%;
    margin-top: 30px; } }

/* ********************************* */
/*       Documents Tweaks            */
/* ********************************* */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
.documentstitle .inner {
  padding: 0 2%; }

.alldocuments {
  margin-bottom: 20px; }

.alldocuments .inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 0; }

.alldocuments .itembox.header-row {
  background: #173b56;
  color: #ffffff;
  font-size: calc(14pt + .3vw);
  font-weight: normal;
  padding: 10px;
  border-color: #173b56;
  line-height: 1.1em;
  box-sizing: border-box; }

.alldocuments .itembox.header-row h2 {
  color: #ffffff; }

.alldocuments .itembox {
  padding: 2px 10px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap; }

.alldocuments .itembox .doc-type {
  width: 80px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box; }

.alldocuments .itembox .doc-title {
  width: calc(100% - 80px - 120px - 185px);
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box; }

.alldocuments .itembox .doc-size {
  text-align: center;
  width: 120px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box; }

.alldocuments .itembox .doc-link {
  text-align: center;
  width: 185px;
  padding: 5px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box; }

.alldocuments .itembox .doc-link .iconbox {
  display: none; }

.alldocuments .itembox .doc-type .icon {
  width: 32px;
  min-height: 32px;
  padding-top: 4px;
  text-align: center; }

.alldocuments .itembox .doc-type .icon:before {
  content: "\f190";
  color: #a32324;
  font-size: 32px;
  font-family: "dashicons";
  display: inline-block;
  margin-top: 5px; }

.alldocuments .itembox .doc-title {
  font-size: 1.1em;
  line-height: 1.2em; }

.alldocuments .itembox.docbutton {
  padding: 2px 0;
  margin-bottom: 4px; }

.alldocuments .itembox.docbutton a.button {
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.alldocuments .itembox.docbutton .doc-title {
  width: 100%;
  padding: 0;
  font-size: .8em;
  display: inline-block;
  text-transform: none; }

.alldocuments .itembox.docbutton .iconbox {
  width: 40px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box; }

.alldocuments .itembox.docbutton .iconbox.one {
  margin-right: 20px; }

.alldocuments .itembox.docbutton .iconbox.two {
  margin-left: 20px; }

.alldocuments .itembox .iconbox.one .icon:before {
  content: "\f190";
  font-size: 32px;
  font-family: "dashicons";
  display: inline-block;
  margin-top: 5px; }

.alldocuments .itembox .iconbox.two .icon:before {
  content: "\f316";
  font-size: 32px;
  font-family: "dashicons";
  display: inline-block;
  margin-top: 5px;
  font-weight: 300; }

@media only screen and (max-width: 800px) {
  .alldocuments .itembox .doc-title {
    width: calc(100% - 80px - 185px); }
  .alldocuments .itembox .doc-size {
    display: none; } }

@media only screen and (max-width: 600px) {
  .alldocuments .itembox .doc-title {
    width: calc(100% - 150px);
    font-size: .85em; }
  .alldocuments .itembox .doc-type {
    display: none; }
  .alldocuments .itembox .doc-link {
    width: 150px;
    font-size: .85em; }
  .alldocuments .itembox .doc-link span {
    display: none; } }

@media only screen and (max-width: 500px) {
  .alldocuments .itembox .doc-title {
    width: calc(100% - 80px); }
  .alldocuments .itembox .doc-link {
    width: 80px; }
  .alldocuments .itembox .doc-link .button {
    display: none; }
  .alldocuments .itembox .doc-title {
    font-size: .95em; }
  .alldocuments .itembox .doc-link .iconbox {
    height: 45px;
    width: 45px;
    position: relative;
    display: inline-block; }
  .alldocuments .itembox .doc-link .iconbox:before {
    content: "\f316";
    font-family: "dashicons";
    background-color: transparent;
    font-size: 40px;
    display: block;
    line-height: 1em;
    transition: all 0.3s; }
  .alldocuments .itembox.header-row .doc-link .textwrapper {
    display: none; } }

/* ********************************* */
/*       Sponsors Tweaks            */
/* ********************************* */
/* Sponsor Page */
.allsponsors .inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

.allsponsors .itembox {
  display: inline-block;
  border: 1px solid #cccccc;
  padding: 10px;
  box-sizing: border-box;
  transition: all 0.3s; }

.allsponsors .imagewrapper img {
  width: 100%;
  height: auto; }

.allsponsors .titlebox {
  color: #445761;
  font-size: calc(12pt + .2vw);
  text-align: center;
  min-height: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: 10px; }

.allsponsors .itembox a .titlebox {
  transition: all 0.3s; }

.allsponsors .itembox a:hover .titlebox {
  color: #42a2d6; }

#carousel1 {
  padding: 20px 0 35px; }

#carousel1 .titlewrap {
  width: 100%;
  background: #181818;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

#carousel1 .titlewrap h2 {
  color: #ffffff;
  font-size: calc(16pt + 1.2vw);
  display: inline-block;
  line-height: 1.2em;
  padding: 30px 0; }

#carousel1 .imagewrapper a {
  display: inline-block; }

#carousel1 .slideshow-container {
  overflow: hidden !important;
  width: 100%;
  margin: 0 auto;
  position: relative; }

#carousel1 .slideshow-container .slideshow {
  overflow: visible !important;
  width: 15%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start; }

#carousel1 .slideshow-container .slideshow .cycle-carousel-wrap {
  display: flex;
  align-items: center; }

#carousel1 .slideshow-container .slideshow .cycle-carousel-wrap a {
  line-height: 0px; }

#carousel1 .slideshow-container .slideshow div {
  padding-left: 10px;
  padding-right: 10px; }

#carousel1 .buttonbox {
  text-align: center;
  margin-top: 20px; }

#carousel1 .buttonbox a.button.more-link {
  background: transparent;
  border: 1px solid #7f7f7f;
  color: #7f7f7f;
  padding: 19px calc(20px + 4vw); }

#carousel1 .buttonbox a.button.more-link:hover {
  background-color: #181818;
  border-color: #181818;
  color: #ffffff; }

.browser-ie-trident #carousel1 .slideshow-container .slideshow .cycle-carousel-wrap {
  display: block; }

.browser-ie-trident #carousel1 .slideshow-container .slideshow .cycle-carousel-wrap img {
  width: 100%; }

@media only screen and (max-width: 1000px) {
  #carousel1 .slideshow-container .slideshow {
    width: 20%; } }

@media only screen and (max-width: 800px) {
  #carousel1 .titlewrap h2 {
    text-align: center; }
  #carousel1 .slideshow-container .slideshow {
    width: 25%; } }

@media only screen and (max-width: 600px) {
  #carousel1 .slideshow-container .slideshow {
    width: 33%; } }

/* ********************************* */
/*       FAQ Accordions              */
/* ********************************* */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
/* Accordion Base behavior */
.accordion-wrapper {
  margin: 20px 0 0 0; }

.accordion .accordion-title {
  border-color: transparent;
  font-size: 1.2em;
  margin-bottom: 2px;
  padding: 18px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: none;
  outline: none;
  line-height: 1em;
  padding-right: 40px;
  position: relative;
  transition: 0.4s;
  box-sizing: border-box; }

.accordion .accordion-title:after {
  content: '\f140';
  font-family: "dashicons";
  font-size: 1.2em;
  float: right;
  margin-left: 5px;
  display: block;
  height: 20px;
  width: 25px;
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto 0; }

.accordion.active .accordion-title:after {
  content: '\f142';
  font-family: "dashicons"; }

.accordion .accordion-text {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: 0.6s ease-in-out;
  opacity: 0;
  border: 1px solid rgba(221, 221, 221, 0);
  box-sizing: border-box; }

.accordion .accordion-text.show {
  opacity: 1;
  max-height: 10000px; }

.accordion.active .accordion-text {
  border-top: 0px solid transparent;
  margin: -2px 0 10px; }

/* FAQ Section */
.faqsection {
  margin-bottom: 20px; }

.faqsection .accordion-wrapper {
  margin-top: 0px; }

/* ********************************* */
/*       VIDEO Tweaks            */
/* ********************************* */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
.videotitle .inner {
  padding: 0 2%; }

.videosection .inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-top: 0; }

.videosection .itembox a {
  width: 100%; }

.videosection .itembox a:after {
  display: none;
  width: 100%; }

.videosection .imagewrapper {
  position: relative;
  overflow: hidden;
  width: 100%; }

.videosection .imagewrapper:after {
  content: "\f522";
  font-family: "dashicons";
  color: rgba(255, 255, 255, 0.85);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-size: calc(50px + 5vw);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center; }

.videosection .imagewrapper img {
  width: 100%;
  height: auto; }

.videosection .title {
  display: block;
  text-align: center;
  font-size: calc(14pt + .4vw);
  line-height: 1.2em;
  margin-top: 7px;
  transition: all 0.3s; }

/*  ALL PROJECTS PAGE */
#projfilterbar {
  background: #0773b3;
  color: #ffffff;
  padding: 0;
  margin-bottom: 30px; }

#projfilterbar .filterwrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap; }

#projfilterbar .filtercat1 {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 27px 35px;
  box-sizing: border-box;
  transition: all 0.3s; }

#projfilterbar .filtercat1.active {
  background: #b01532; }

#projfilterbar .filtercat1:hover {
  background: #b01532; }

#projfilterbar .imagewrapper {
  display: none; }

#projfilterbar .titlewrap {
  font-size: calc(14pt + .2vw);
  text-transform: uppercase;
  letter-spacing: 1px; }

#allprojects {
  padding: 0; }

#allprojects .inner {
  max-width: 100%;
  padding: 0 2%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }

#allprojects .projitem {
  width: 30.83%;
  margin: 0 1.25% 50px;
  text-align: center;
  transition: all 0.8s; }

#allprojects .projitem .imagewrapper {
  overflow: hidden; }

#allprojects .projitem img {
  transition: all 0.3s; }

#allprojects .projitem a:hover img {
  transform: scale3d(1.1, 1.1, 1); }

#allprojects .projitem a .titlewrap {
  color: #040404;
  margin-top: 12px;
  font-size: calc(14pt + .25vw);
  font-weight: 400;
  line-height: 1.3em;
  text-transform: uppercase; }

#allprojects .projitem a .subtitlewrap {
  color: #0773b3;
  font-size: calc(12pt + .1vw);
  font-weight: 400;
  line-height: 1.2em;
  letter-spacing: 1.2px;
  text-transform: uppercase; }

#allprojects .projitem.nodisplay {
  transform: scale(0);
  font-size: 0px !important;
  line-height: 0 !important;
  height: 0;
  opacity: 0;
  width: 0px;
  margin: 0;
  padding: 0; }

#allprojects .projitem.absolute {
  opacity: 0;
  width: 0px;
  margin: 0; }

#allprojects .projitem.outofpricerange {
  transform: scale(0); }

#allprojects .projitem.absolute2 {
  opacity: 0;
  width: 0px;
  margin: 0; }

/* SINGLE PROJECTS PAGE */
#content.singleproject {
  padding: 4.4vw 0; }

#content.singleproject .inner {
  max-width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

#content.singleproject .inner .leftside {
  width: 45%;
  padding: 20px 74px;
  box-sizing: border-box; }

#content.singleproject .inner .leftside .item {
  color: #0773b3;
  font-size: calc(16pt + .1vw);
  font-weight: 400;
  margin-bottom: 14px; }

#content.singleproject .inner .leftside .item span {
  color: #445761;
  font-weight: 700;
  margin-right: 5px;
  letter-spacing: -.8px; }

#content.singleproject .inner .leftside .textwrap {
  margin-top: 40px; }

#content.singleproject .inner .leftside .textwrap p {
  color: #040404;
  line-height: 2.1em; }

#content.singleproject .inner .rightside {
  width: 53%; }

/* Single Project Bottom Gallery */
.project-gallery-wrapper {
  background: #42a2d6; }

#project-top-gallery {
  background: transparent;
  padding: 30px 0 20px; }

#project-top-gallery .inner {
  position: relative; }

#project-top-gallery .cg-slideshow {
  position: relative;
  margin: 0 auto;
  max-height: 75vh;
  width: auto; }

#project-top-gallery .cg-slideshow .slide-element {
  text-align: center; }

#project-top-gallery .cg-slideshow .slide-element img {
  vertical-align: top;
  max-height: 75vh;
  width: auto; }

#project-top-gallery .cycle-next {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  right: 0vw;
  cursor: pointer;
  margin-top: -25px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

#project-top-gallery .cycle-prev {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 0vw;
  cursor: pointer;
  margin-top: -25px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

#project-top-gallery .cycle-next:before {
  color: #ffffff;
  content: "\f345";
  font-size: 115px;
  font-family: "dashicons";
  display: inline-block;
  opacity: 1;
  position: relative;
  text-shadow: 0 0 4px #000000; }

#project-top-gallery .cycle-prev:before {
  color: #ffffff;
  content: "\f341";
  font-size: 115px;
  font-family: "dashicons";
  display: inline-block;
  opacity: 1;
  position: relative;
  text-shadow: 0 0 4px #000000; }

#project-top-gallery .cycle-next:hover:before {
  color: #b01532; }

#project-top-gallery .cycle-prev:hover:before {
  color: #b01532; }

#project-bottom-gallery {
  padding: 0px 0 30px; }

#project-bottom-gallery .js-gallery {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

#project-bottom-gallery .js-gallery .element.image {
  display: inline-block;
  width: 16%;
  margin: 0 .8% .8% 0;
  position: relative;
  line-height: 0px; }

#project-bottom-gallery .js-gallery .element.image:nth-child(6n+6) {
  margin-right: 0; }

#project-bottom-gallery .js-gallery .element.image .overlay {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s;
  opacity: 0; }

#project-bottom-gallery .js-gallery .element.image .overlay:hover {
  opacity: 1; }

#project-bottom-gallery .js-gallery .element.image .overlay:before {
  color: #ffffff;
  content: "\f179";
  font-family: "dashicons";
  font-size: calc(16pt + 3vw); }

@media only screen and (max-width: 1300px) {
  #projfilterbar .filtercat1 {
    width: 20%; } }

@media only screen and (max-width: 1100px) {
  #allprojects .projitem {
    width: 46%;
    margin: 0 2% 50px; } }

@media only screen and (max-width: 1000px) {
  #project-top-gallery .cycle-next:before {
    font-size: 85px; }
  #project-top-gallery .cycle-prev:before {
    font-size: 85px; }
  #project-bottom-gallery .js-gallery .element.image:nth-child(6n+6) {
    margin-right: 1.25%; }
  #project-bottom-gallery .js-gallery .element.image {
    width: 19%;
    margin: 0 1.25% 1.25% 0; }
  #project-bottom-gallery .js-gallery .element.image:nth-child(5n+5) {
    margin-right: 0; }
  #content.singleproject .inner {
    justify-content: center; }
  #content.singleproject .inner .leftside {
    order: 2; }
  #content.singleproject .inner .leftside, #content.singleproject .inner .rightside {
    width: 100%; } }

@media only screen and (max-width: 950px) {
  #project-top-gallery .cg-slideshow {
    position: relative;
    margin: 0 auto;
    max-height: 50vw;
    width: auto; }
  #project-top-gallery .cg-slideshow .slide-element img {
    vertical-align: top;
    max-height: 50vw;
    width: auto; } }

@media only screen and (max-width: 800px) {
  #project-bottom-gallery .js-gallery .element.image:nth-child(5n+5) {
    margin-right: 1.33%; }
  #project-bottom-gallery .js-gallery .element.image:nth-child(6n+6) {
    margin-right: 1.33%; }
  #project-bottom-gallery .js-gallery .element.image {
    width: 24%;
    margin: 0 1.33% 1.33% 0; }
  #project-bottom-gallery .js-gallery .element.image:nth-child(4n+4) {
    margin-right: 0; } }

@media only screen and (max-width: 700px) {
  #projfilterbar .filtercat1 {
    width: 33.33%; }
  #allprojects .projitem:nth-child(3n+3),
  #allprojects .projitem {
    width: 100%;
    max-width: 600px;
    margin: 0 auto 50px; }
  #allprojects .projitem.absolute {
    position: absolute; } }

@media only screen and (max-width: 500px) {
  #projfilterbar .filtercat1 {
    width: 100%; }
  #project-top-gallery .cycle-next:before {
    font-size: 50px; }
  #project-top-gallery .cycle-prev:before {
    font-size: 50px; }
  #content.singleproject .inner .leftside {
    padding: 20px; } }

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
/* ********************************* */
/*       Gallery Teaser Page         */
/* ********************************* */
.gallery-teaser-section .inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

.gallery-teaser-section .itembox .imagewrapper {
  overflow: hidden;
  transition: all 0.3s; }

.gallery-teaser-section .itembox .imagewrapper img {
  transition: all 0.3s;
  width: 100%;
  height: auto; }

.gallery-teaser-section .itembox:hover .imagewrapper img {
  transform: scale3d(1.1, 1.1, 1); }

.gallery-teaser-section .itembox .title {
  padding: 10px 20px;
  font-size: 1.2em;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  transition: all 0.3s; }

/* ********************************* */
/*       Gallery Wrapper             */
/* ********************************* */
.gallery-wrapper {
  padding: 20px 0;
  background-color: #ffffff;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  position: relative; }

.gallery-wrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.gallery-wrapper .itembox {
  margin-bottom: 30px; }

.gallery-wrapper .itembox:nth-last-of-type(1) {
  margin-bottom: 0; }

/* ********************************* */
/*       Gallery Slider              */
/* ********************************* */
#gallery-slider {
  background: transparent;
  padding: 0 2%;
  margin-bottom: 30px; }

#gallery-slider .inner {
  position: relative; }

#gallery-slider .gallery-slideshow {
  position: relative;
  margin: 0 auto;
  max-height: 75vh;
  width: auto; }

#gallery-slider .gallery-slideshow .slide-element {
  text-align: center; }

#gallery-slider .gallery-slideshow .slide-element img {
  vertical-align: top;
  max-height: 75vh;
  width: auto; }

#gallery-slider .cycle-next {
  width: 50px;
  height: 100px;
  position: absolute;
  top: 50%;
  right: 2.2%;
  cursor: pointer;
  margin-top: -25px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

#gallery-slider .cycle-prev {
  width: 50px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 2.2%;
  cursor: pointer;
  margin-top: -25px;
  display: flex;
  justify-content: space-around;
  align-items: center; }

#gallery-slider .cycle-next:before {
  content: "\f345";
  font-size: 115px;
  font-family: "dashicons";
  display: inline-block;
  opacity: 1;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
  position: relative;
  left: -65%; }

#gallery-slider .cycle-prev:before {
  content: "\f341";
  font-size: 115px;
  font-family: "dashicons";
  display: inline-block;
  opacity: 1;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
  position: relative;
  left: -65%; }

/* ********************************* */
/*       Gallery Squares             */
/* ********************************* */
.gallerybox {
  padding: 0; }

.gallerybox .inner {
  padding-bottom: 0; }

.gallerybox .js-gallery {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

.gallerybox .js-gallery .itembox {
  position: relative;
  line-height: 0px; }

.gallerybox .js-gallery .itembox .overlay {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s;
  opacity: 0; }

.gallerybox .js-gallery .itembox .overlay:hover {
  opacity: 1; }

.gallerybox .js-gallery .itembox .overlay:before {
  color: #ffffff;
  content: "\f179";
  font-family: "dashicons";
  font-size: calc(16pt + 3vw); }

.gallerybox .js-gallery.masonary .itembox {
  margin-bottom: 0; }

/* ********************************* */
/*       Gallery Masonary             */
/* ********************************* */
.gallerybox .js-gallery .imagewrapper.masonary {
  position: relative;
  line-height: 0px;
  box-sizing: border-box; }

.gallerybox .js-gallery .imagewrapper.masonary .overlay {
  background: rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s;
  opacity: 0; }

.gallerybox .js-gallery .imagewrapper.masonary .overlay:hover {
  opacity: 1; }

.gallerybox .js-gallery .imagewrapper.masonary .overlay:before {
  color: #ffffff;
  content: "\f179";
  font-family: "dashicons";
  font-size: calc(16pt + 3vw); }

.masonary .itembox.col-two {
  width: 50%;
  margin-right: 0;
  margin-bottom: 30px;
  display: block; }

.masonary .itembox.col-three {
  width: 33.33%;
  margin-right: 0;
  margin-bottom: 30px;
  display: block; }

.masonary .itembox.col-four {
  width: 25%;
  margin-right: 0;
  margin-bottom: 30px;
  display: block; }

.masonary .itembox.col-five {
  width: 20%;
  margin-right: 0;
  margin-bottom: 30px;
  display: block; }

.masonary .itembox.col-six {
  width: 16.66%;
  margin-right: 0;
  margin-bottom: 30px;
  display: block; }

@media only screen and (max-width: 1200px) {
  .masonary .itembox.col-six {
    width: 20%; } }

@media only screen and (max-width: 1024px) {
  .masonary .itembox.col-three {
    width: 33.33%; }
  .masonary .itembox.col-four {
    width: 25%; }
  .masonary .itembox.col-five {
    width: 25%; }
  .masonary .itembox.col-six {
    width: 25%; } }

@media only screen and (max-width: 1000px) {
  .gallery-teaser-section .gallerybox:nth-child(3n+3) {
    margin-right: 2%; }
  .gallery-teaser-section .gallerybox {
    display: inline-block;
    width: 49%;
    margin: 0 2% 2% 0; }
  .gallery-teaser-section .gallerybox:nth-child(2n+2) {
    margin-right: 0; } }

@media only screen and (max-width: 850px) {
  .masonary .itembox.col-five {
    width: 33.33%; }
  .masonary .itembox.col-six {
    width: 33.33%; } }

@media only screen and (max-width: 800px) {
  #gallery-slider .cycle-next:before {
    font-size: 75px; }
  #gallery-slider .cycle-prev:before {
    font-size: 75px; } }

@media only screen and (max-width: 768px) {
  .masonary .itembox.col-four {
    width: 50%; } }

@media only screen and (max-width: 640px) {
  .masonary .itembox.col-two {
    width: 50%; }
  .masonary .itembox.col-three {
    width: 50%; }
  .masonary .itembox.col-five {
    width: 50%; }
  .masonary .itembox.col-six {
    width: 50%; } }

@media only screen and (max-width: 500px) {
  #gallery-slider {
    padding: 0 0px; }
  .masonary .itembox.col-two {
    width: 100%; }
  .masonary .itembox.col-three {
    width: 100%; }
  .masonary .itembox.col-four {
    width: 100%; }
  .masonary .itembox.col-five {
    width: 100%; }
  .masonary .itembox.col-six {
    width: 100%; }
  #gallery-squares .itembox.col-six {
    width: 50%; }
  #gallery-squares .itembox.col-five {
    width: 50%; }
  #gallery-squares .itembox.col-four {
    width: 50%; }
  #gallery-squares .itembox.col-three {
    width: 50%; }
  #gallery-squares .itembox.col-two {
    width: 50%; } }

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
#alltestimonials {
  background: transparent; }

#alltestimonials .inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

#alltestimonials .itembox {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  z-index: 50; }

#alltestimonials .testimonial {
  border-radius: 20px;
  padding: 20px 20px 30px 20px;
  position: relative; }

#alltestimonials .testimonial {
  font-style: italic;
  font-size: 1.0em; }

#alltestimonials .testimonial:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1; }

#alltestimonials .testimonial .stars {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }

#alltestimonials .testimonial .stars .iconbox:after {
  content: "\f155";
  font-family: "dashicons";
  font-size: 40px;
  display: inline-block;
  font-style: normal; }

#alltestimonials .itembox img {
  width: 100%;
  height: auto; }

#alltestimonials .itembox .name {
  font-weight: 700;
  margin-top: 40px;
  width: 100%;
  text-align: center; }

#alltestimonials .itembox .subtitle {
  margin-top: 10px;
  width: 100%;
  text-align: center; }

.js_left_right_slider {
  background: #ffffff;
  border: 2px solid #ffffff;
  padding: calc(20px + 2vw) 0 calc(20px + 2vw) 0;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.js_left_right_slider .overflow_hidden_box {
  background: transparent;
  overflow: hidden;
  position: relative; }

.js_left_right_slider .hold_vertical_open {
  overflow-x: visible;
  width: 100000px;
  opacity: 0; }

.js_left_right_slider .itembox {
  width: 900px;
  display: inline-block; }

.js_left_right_slider .slider_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.js_left_right_slider .slider_box .itembox {
  position: absolute;
  top: 50%;
  left: 50%; }

.js_left_right_slider .overlay {
  position: absolute; }

.js_left_right_slider .dot_nav {
  display: none; }

.js_left_right_slider .arrow_nav {
  display: none; }

/* Slider Width Controls */
.js_left_right_slider.one {
  width: 1200px;
  box-sizing: border-box;
  margin: 0 auto; }

.js_left_right_slider.one .itembox {
  width: 1200px;
  padding: 20px 70px;
  box-sizing: border-box; }

/* Enable Arrow Navigation */
/* Enable Dots Navigation */
.js_left_right_slider.one {
  padding-bottom: calc(20px + 2vw + 30px); }

.js_left_right_slider.one .slider_box .itembox {
  top: calc(50% - 15px); }

.js_left_right_slider.one .dot_nav {
  bottom: 0;
  height: 30px;
  width: 100%;
  margin-top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 20;
  line-height: 0; }

.js_left_right_slider.one .dot_nav .centerbox {
  margin-top: 3px;
  line-height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.js_left_right_slider.one .dot_nav .dot {
  background: rgba(0, 0, 0, 0.5);
  width: 15px;
  height: 15px;
  margin: 0 3px;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.3s;
  cursor: pointer; }

.js_left_right_slider.one .dot_nav .dot.active {
  background-color: #e00e2d; }

.js_left_right_slider.one .dot_nav .dot:hover {
  background-color: #e00e2d; }

/* Move Dots Outside of background box. */
.js_left_right_slider.one {
  background: transparent;
  border: 2px solid transparent; }

.js_left_right_slider.one .overflow_hidden_box {
  background: white; }

.js_left_right_slider.one .dot_nav {
  position: relative;
  margin-top: 20px; }

.js_left_right_slider.one .slider_box .itembox {
  top: calc(50%); }

/* animate slides */
.js_left_right_slider.one .slider_box .itembox {
  transform: translateX(100vw) translateY(-50%);
  opacity: 0;
  transition: all 2s;
  z-index: 1;
  display: block; }

.js_left_right_slider.one .slider_box .itembox.step1 {
  z-index: 2;
  opacity: .3; }

.js_left_right_slider.one .slider_box .itembox.step2 {
  z-index: 3;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%); }

.js_left_right_slider.one .slider_box .itembox.step3 {
  z-index: 2;
  opacity: .3;
  transform: translateX(calc(-100vw - 50%)) translateY(-50%); }

.js_left_right_slider.one .slider_box .itembox.step4 {
  z-index: 1;
  opacity: 0;
  transform: translateX(100vw) translateY(-50%);
  display: none; }

/* Make the Infor Look Pretty */
.js_left_right_slider.one .itembox .titlewrap {
  font-size: calc(16pt + .7vw);
  color: #000000;
  font-weight: 700;
  line-height: 1.1em;
  margin: 0 0 28px;
  text-transform: none;
  letter-spacing: 2px; }

.js_left_right_slider.one .itembox .textwrap p {
  line-height: 1.8em; }

.js_left_right_slider.one .itembox .visit_line {
  color: #000000;
  font-size: calc(14pt + .3vw);
  font-weight: 700;
  margin-top: 28px;
  text-align: right;
  letter-spacing: 0px;
  white-space: pre-wrap; }

/* DEVELOPER COLOR DISPLAYS */
@media only screen and (max-width: 1280px) {
  /* Slider Width Controls */
  .js_left_right_slider.one {
    width: 93.75vw;
    box-sizing: border-box;
    margin: 0 auto; }
  .js_left_right_slider.one .itembox {
    width: 93.75vw;
    padding: 20px 70px;
    box-sizing: border-box; } }

/* Starting banner css */
.minislideshow {
  position: relative;
  line-height: 0; }

.minislideshow.slideshow .itembox:nth-child(1) {
  position: relative; }

.minislideshow.slideshow .itembox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  transition: all 1s;
  margin-bottom: 0px !important; }

.minislideshow.slideshow .itembox.inactive {
  opacity: 0;
  z-index: 2; }

/* this makes the transition between slides smoother */
.minislideshow.slideshow .itembox.active {
  opacity: 1;
  z-index: 3; }

.minislideshow .slidebox {
  line-height: 0; }

.minislideshow .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden; }

.minislideshow .centerbox {
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  line-height: 1.1em; }

.minislideshow .titlewrap {
  color: #185297;
  font-size: calc(16pt + 2vw);
  line-height: 1.1em;
  font-weight: 700; }

.minislideshow .subtitlewrap {
  color: #242424;
  font-size: calc(12pt + .4vw);
  margin: 10px 0 0 0;
  line-height: 1.1em; }

.minislideshow .buttonwrap {
  margin: 22px 0 0 0;
  z-index: 10;
  line-height: 1.1em; }

.minislideshow .overlay.nothingtoshow {
  display: none; }

.minislideshow.tall .overlay {
  justify-content: flex-start;
  max-width: calc(1600px - 4%);
  margin: 0 auto;
  right: 0;
  padding: 0; }

.minislideshow.tall .overlay .centerbox {
  padding: 0;
  max-width: 35%; }

/*  animate overlay box items  */
.minislideshow .itembox.active.starting .overlay.animateoverlay-no .centerbox {
  opacity: 1; }

/* this makes sure when not animating the first slide when first starting doesnt just POP in place */
.minislideshow .itembox.active.starting .overlay.animateoverlay-yes .centerbox {
  opacity: 0;
  transform: translateY(100vh); }

/* this helps the first slide when first starting the slideshow to animate correct */
.minislideshow .itembox .overlay.animateoverlay-yes .centerbox {
  opacity: 0;
  transform: translateY(100vh); }

.minislideshow .itembox.active .overlay.animateoverlay-yes .centerbox {
  transition-delay: .5s;
  transition: all 1s;
  transform: translateY(0);
  opacity: 1; }

.minislideshow .itembox.inactive .overlay.animateoverlay-yes .centerbox {
  transform: translateY(0);
  opacity: 0; }

.minislideshow .slideshowdots {
  position: absolute;
  width: 100%;
  bottom: 10px;
  z-index: 300;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

.minislideshow .slideshowdots .centerbox {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

.minislideshow .slideshowdots .dot {
  display: inline-block;
  background: rgba(255, 255, 255, 0.6);
  border: 2px solid #ffffff;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  border-radius: 50%;
  transition: all 0.3s;
  box-sizing: border-box; }

.minislideshow .slideshowdots .dot.active {
  background-color: white; }

.minislideshow .slideshowdots .dot:hover {
  border-color: #000000; }

#content.upcomingevents-page .inner {
  padding-bottom: 0; }

#upcomingevents .itemwrap {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

#upcomingevents .itemwrap .topbox {
  line-height: 0; }

#upcomingevents .itemwrap .topbox img {
  width: 100%;
  height: auto; }

#upcomingevents .itemwrap .middlebox {
  background: #f9f9f9;
  border: 1px solid #cacaca;
  border-top: 0px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 10px 15px;
  box-sizing: border-box; }

#upcomingevents .itemwrap .middlebox .centerbox {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

#upcomingevents .itemwrap .middlebox .leftside {
  color: #de1e07;
  font-weight: 600;
  font-size: calc(20px + 1.5vw);
  max-width: 70px;
  padding-right: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

#upcomingevents .itemwrap .middlebox .rightside {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

#upcomingevents .itemwrap .middlebox .rightside .dateline {
  width: 100%;
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; }

#upcomingevents .itemwrap .middlebox .rightside .dateline .month {
  display: inline-block;
  margin-right: 10px; }

#upcomingevents .itemwrap .middlebox .rightside .dateline .year {
  display: inline-block; }

#upcomingevents .itemwrap .middlebox .rightside .timeline {
  color: #6d6d6d;
  font-size: .75em;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 6px; }

#upcomingevents .itemwrap .middlebox .rightside .timeline .start {
  display: inline-block;
  margin-right: 10px; }

#upcomingevents .itemwrap .middlebox .rightside .timeline .end {
  display: inline-block; }

#upcomingevents .itemwrap .bottombox {
  background: #ffffff;
  border: 1px solid #cacaca;
  border-top: 0px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  padding: 20px 15px 15px;
  box-sizing: border-box; }

#upcomingevents .itemwrap .bottombox .centerbox {
  min-height: 230px;
  width: 100%; }

#upcomingevents .itemwrap .bottombox .centerbox .titlewrap {
  font-size: 1.2em;
  font-weight: 700;
  line-height: 1.3em; }

#upcomingevents .itemwrap .bottombox .centerbox .textwrap {
  color: #6d6d6d;
  font-size: .75em; }

#upcomingevents.fullpage .inner {
  padding-bottom: 0;
  margin-bottom: -20px; }

#upcomingevents.fullpage .itemwrap .middlebox {
  border-top: 1px solid #cacaca;
  align-items: center; }

#upcomingevents.fullpage .itemwrap .middlebox .leftside {
  max-width: 100%;
  width: 100%;
  padding: 0; }

#upcomingevents.fullpage .itemwrap .middlebox .rightside {
  width: 100%; }

#upcomingevents.fullpage .itemwrap .middlebox .rightside .dateline {
  justify-content: center;
  font-size: 1.5em;
  margin-top: 20px; }

#upcomingevents.fullpage .itemwrap .middlebox .rightside .timeline {
  justify-content: center;
  font-size: 1em;
  margin-top: 20px; }

@media only screen and (max-width: 1000px) {
  #upcomingevents.fullpage .itemwrap .topbox {
    width: 50%; }
  #upcomingevents.fullpage .itemwrap .middlebox {
    width: 50%; } }

@media only screen and (max-width: 500px) {
  #upcomingevents.fullpage .itemwrap .topbox {
    width: 100%; }
  #upcomingevents.fullpage .itemwrap .middlebox {
    width: 100%; } }

@media only screen and (max-width: 500px) {
  #upcomingevents .itemwrap .middlebox .leftside {
    font-size: calc(40px + 1.5vw); } }

/*  ------------------------------------------------------------------------------------  */
/*  -------------- START OF GENERIC CSS RULES ------------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
body {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  position: relative;
  overflow: hidden; }

html body#tinymce {
  padding: 5px 15px !important;
  box-sizing: border-box; }

h1, h1 a {
  font-size: calc(16pt + .9vw);
  font-family: "Poppins",sans-serif;
  font-weight: 700;
  letter-spacing: 3.6px;
  line-height: 1.2em;
  margin: 10px 0 10px;
  text-transform: none; }

h2, h2 a {
  font-size: calc(16pt + .8vw);
  font-family: "Poppins",sans-serif;
  font-weight: 700;
  letter-spacing: 3.6px;
  line-height: 1.2em;
  margin: 10px 0;
  text-transform: none; }

h3, h3 a {
  font-size: calc(16pt + .7vw);
  font-family: "Poppins",sans-serif;
  font-weight: 700;
  letter-spacing: 3.6px;
  line-height: 1.2em;
  margin: 10px 0;
  text-transform: none; }

h4, h4 a {
  font-size: calc(14pt + .6vw);
  font-family: "Poppins",sans-serif;
  font-weight: 700;
  letter-spacing: 3.6px;
  line-height: 1.2em;
  margin: 10px 0; }

h5, h5 a {
  font-size: calc(14pt + .5vw);
  font-family: "Poppins",sans-serif;
  font-weight: 700;
  letter-spacing: 3.6px;
  line-height: 1.2em;
  margin: 10px 0; }

h6, h6 a {
  font-size: calc(14pt + .4vw);
  font-family: "Poppins",sans-serif;
  font-weight: 700;
  letter-spacing: 3.6px;
  line-height: 1.2em;
  margin: 10px 0; }

section:after {
  line-height: 0; }

/*This was causing a 1px white space issue between 2 dark colors.  fix for core. */
.inner {
  width: 100%;
  max-width: 1600px;
  box-sizing: border-box;
  padding: 30px 2%;
  margin: 0 auto; }

p {
  margin: 20px 0; }

p, ul, ol, li {
  line-height: 1.5em; }

ul ul {
  list-style-type: circle;
  margin: 5px 0; }

strong, b {
  font-weight: 700; }

i {
  font-style: italic; }

img {
  max-width: 100%;
  height: auto; }

button {
  border: 0px;
  padding: 0;
  margin: 0; }

a, a:link, a:visited {
  transition: all 0.3s; }

/* BUTTONS ---------- */
.form-item input.wpcf7-submit,
.mce-content-body a.button[data-mce-selected],
.af-submit button.acf-button,
button.af-submit-button,
a.button {
  font-weight: 400;
  font-size: 18px;
  padding: 10px 15px;
  width: inherit;
  border-width: 1px;
  border-style: solid;
  border-radius: 0px;
  white-space: pre-wrap;
  line-height: 1em;
  display: inline-block;
  text-transform: capitalize;
  box-sizing: border-box;
  transition: all 0.3s;
  letter-spacing: 1.8px; }

/*      HOVER      */
.form-item input.wpcf7-submit:hover,
.mce-content-body a.button[data-mce-selected]:hover,
.af-submit button.acf-button:hover,
button.af-submit-button:hover,
a.button:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }

a.button.small {
  padding: 2px 12px 4px;
  border-radius: 5px;
  text-transform: none; }

/* FORMS ---------- */
form.acf-form {
  margin-top: 0px; }

form.acf-form .acf-fields > .acf-field {
  border-top: none;
  padding: 10px 0; }

form.acf-form .acf-form-submit {
  padding: 10px 0; }

.form-item input.wpcf7-submit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0; }

/* This is a FIX for Safari on a CELL PHONE. for the button displays. */
/* WYSIWYG VIDEOS ---------- */
/* automatically add a video Play button to a image thats linked as a popup youtube video. */
a.popup-youtube {
  position: relative;
  display: inline-block;
  line-height: 0;
  overflow: hidden; }

a.popup-youtube:after {
  content: "\f522";
  font-family: "dashicons";
  color: rgba(255, 255, 255, 0.7);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-size: calc(50px + 5vw);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center; }

a.popup-youtube img {
  transition: all 0.3s; }

a.popup-youtube:hover img {
  transform: scale3d(1.1, 1.1, 1); }

.video-page a.popup-youtube:after {
  display: none; }

/* so the play button doesnt double up on a video page that already has it. */
/* COMMON CSS CLASSES ---------- */
.itembox {
  display: inline-block;
  box-sizing: border-box;
  transition: all 0.3s; }

.centerbox {
  display: inline-block; }

.imagewrapper {
  position: relative;
  overflow: hidden;
  line-height: 0px; }

/* kill off the inherit line height thats on all divs causing 4px at the bottom of a div thats empty */
.imagewrapper img {
  transition: all 0.3s; }

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap; }

.leftside {
  width: 48%;
  display: inline-block; }

.rightside {
  width: 48%;
  display: inline-block; }

/* NOT NEEDED ANYMORE? ---------- */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- END OF GENERIC CSS RULES --------------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
#header1 {
  padding: 0;
  position: relative;
  z-index: 10;
  width: 100%;
  border-bottom: 1px solid #0773B3; }

#header1 .inner {
  padding: 15px 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

#header1 .inner .widget_nav_menu {
  width: calc(73% - 250px); }

/**/
#header2 h2 {
  font-size: calc(16pt + 0.5vw);
  line-height: 1.8em; }

#header2 .inner {
  padding: 15px 2%; }

#header2 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center; }

#header2 .inner #wysiwyg_widgets_widget-4 {
  width: 54%; }

#header2 .inner #wysiwyg_widgets_widget-5 {
  width: 40%; }

#header2 .inner #wysiwyg_widgets_widget-5 p {
  line-height: 1.68em;
  font-weight: 300;
  font-size: 18px; }

/**/
#header3 .inner {
  padding: 0 2%; }

.is-subpage #header3 .inner {
  margin: 0;
  max-width: unset;
  padding: 0; }

.all-content-wrapper {
  padding: 0;
  box-sizing: border-box; }

/**/
.outer.front-page .inner h1 {
  position: relative;
  width: 38%;
  min-width: 578px;
  color: #221F1F; }

.outer.front-page .inner h1::after {
  content: '';
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 47%;
  height: 7px;
  background: #FBAC42; }

/**/
.is-homepage #stagger .itembox .leftside {
  width: 49%; }

.is-homepage #stagger .stagger-part:nth-of-type(1) h2 {
  color: #B01532; }

.is-homepage #stagger .stagger-part:nth-of-type(2) a.button {
  background-color: #0773B3;
  border-color: #0773B3; }

.is-homepage #stagger .stagger-part:nth-of-type(2) a.button:hover {
  background-color: #445761;
  border-color: #445761; }

.is-homepage #stagger .itembox .rightside {
  width: 49%; }

.is-homepage #stagger .itembox .rightside p {
  margin: 20px 0; }

.is-homepage #stagger .itembox figure {
  height: 18vw;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat; }

.is-homepage .minislideshow, .is-homepage .minislideshow .slidebox {
  width: 100%; }

/**/
#void-section {
  border: 5px solid #FBAC42;
  color: #ffffff;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat; }

#void-section::before {
  content: '';
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(to right, black, rgba(0, 0, 0, 0)); }

#void-section .inner {
  padding-top: 20vw;
  padding-bottom: 50px;
  position: relative;
  z-index: 5; }

#void-section .inner .void-textwrap {
  width: 50%; }

#void-section .inner .void-textwrap h2 {
  color: #FBAC42; }

#void-section .inner .void-textwrap p {
  line-height: 1.8em;
  margin: 10px 0; }

#void-section .inner .void-textwrap a.button {
  background-color: #FBAC42;
  border-color: #FBAC42; }

#void-section .inner .void-textwrap a.button:hover {
  background-color: #445761;
  border-color: #445761; }

/**/
#sealing-section .inner p {
  margin: 0; }

#sealing-section .inner a.button {
  margin: 20px 0;
  background-color: #0773B3;
  border-color: #0773B3; }

#sealing-section .inner a.button:hover {
  background-color: #445761;
  border-color: #445761; }

#sealing-section .inner .sealing-top-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }

#sealing-section .inner .sealing-top-container .leftside {
  width: 43%; }

#sealing-section .inner .sealing-top-container .rightside {
  width: 55%; }

#sealing-section .inner .sealing-top-container h2 {
  line-height: 1.5em; }

#sealing-section .inner .sealing-image-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }

#sealing-section .inner .sealing-image-container figure {
  width: 28%;
  height: 26vw;
  background-size: cover;
  background-repeat: no-repeat; }

#sealing-section .inner .sealing-image-container figure:nth-of-type(1) {
  width: 70%; }

/**/
#repair-section {
  position: relative;
  background-size: cover;
  background-attachment: fixed; }

#repair-section h2 {
  color: #ffffff; }

#repair-section .repair-overlay-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5; }

#repair-section .inner {
  position: relative;
  z-index: 10;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 2%; }

#repair-section .inner .leftside {
  width: 42%; }

#repair-section .inner .leftside .imagewrapper {
  width: 100%; }

#repair-section .inner .rightside {
  width: 55%; }

#repair-section .inner .rightside p {
  letter-spacing: 1.8px; }

#repair-section .inner .rightside a.button {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #B01532; }

#repair-section .inner .rightside a.button:hover {
  background-color: #445761;
  border-color: #445761;
  color: #ffffff; }

/**/
#footer1 {
  border-top: 1px solid #0773B3; }

#footer1 .inner {
  padding: 30px 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

#footer1 .inner #wysiwyg_widgets_widget-2 p {
  margin: 0; }

#footer1 .inner #wysiwyg_widgets_widget-3 p {
  margin: 5px 0;
  line-height: 1.3em; }

#footer1 .inner #wysiwyg_widgets_widget-3 p a {
  font-size: 17px;
  font-weight: 600;
  color: #221F1F; }

#footer1 .inner #wysiwyg_widgets_widget-3 p a:hover {
  color: #B01532; }

#footer1 .inner #blockgroup2 {
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: flex-end;
  width: 48%; }

#footer1 .inner #blockgroup2 .nav_menu-9 {
  margin-top: 20px; }

#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox {
  width: 100%; }

#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu #menu-item-559 {
  display: none; }

/**/
#footer2 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

#footer3 .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; }

#bfm-credits {
  background: #0c0c0c;
  color: #747b81;
  text-align: center; }

#bfm-credits .inner {
  font-size: .7em;
  line-height: 1.2em;
  padding: 15px 2%; }

#bfm-credits a {
  color: #747b81; }

#bfm-credits a:hover {
  color: #ffffff; }

.js-back-to-top {
  position: fixed;
  height: 50px;
  bottom: 0;
  right: 20px;
  border-width: 1px;
  border-style: solid;
  border-radius: 20px 20px 0 0;
  transition: all 0.5s;
  transform: translateY(25px);
  z-index: 10000; }

.js-back-to-top:before {
  top: calc(50% - 5px);
  content: "\f342";
  font-family: "dashicons";
  font-size: 40px; }

.js-back-to-top:hover {
  transform: translateY(2px);
  cursor: pointer; }

@media only screen and (min-width: 1921px) {
  body {
    font-size: calc(12.5pt + .2vw); }
  .inner {
    max-width: 1877px; }
  .slideshow-banner-box {
    max-height: 70vh;
    overflow: hidden; }
  .slideshow-banner-box .itembox {
    width: 100%;
    max-height: 70vh; }
  .slideshow-banner-box .itembox figure.imagewrapper img {
    width: 100%;
    height: auto; } }

@media only screen and (max-width: 1100px) {
  #repair-section .inner {
    flex-wrap: wrap;
    justify-content: center; }
  #repair-section .inner .leftside {
    width: 63%;
    margin-bottom: 15px; }
  #repair-section .inner .rightside {
    width: 100%; }
  #footer1 .inner {
    padding-bottom: 15px; }
  #footer1 .inner #blockgroup2 {
    width: 100%; }
  #footer1 .inner #wysiwyg_widgets_widget-2 {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px dotted #0773b3;
    padding-bottom: 10px; }
  #footer1 .inner #wysiwyg_widgets_widget-3 {
    width: 100%;
    border-top: 1px dotted #0773b3;
    padding-top: 10px;
    text-align: center; } }

@media only screen and (max-width: 1000px) {
  .is-homepage #stagger .itembox .leftside {
    width: 100%; }
  .is-homepage #stagger .itembox .rightside {
    width: 100%; }
  .is-homepage #stagger .stagger-part:nth-child(even) .rightside {
    order: 3; }
  .is-homepage #stagger .itembox figure {
    height: 49vw; }
  #void-section {
    background-position: center; }
  #void-section .inner .void-textwrap {
    width: 70%; } }

@media only screen and (max-width: 900px) {
  #header1 .inner .widget_nav_menu {
    width: calc(97% - 250px); } }

@media only screen and (max-width: 800px) {
  #sealing-section .inner .sealing-top-container {
    flex-wrap: wrap; }
  #sealing-section .inner .sealing-top-container .leftside {
    width: 100%; }
  #sealing-section .inner .sealing-top-container .rightside {
    width: 100%; } }

@media only screen and (max-width: 700px) {
  .widget_nav_menu .menu.nav-active {
    width: 95vw; }
  #header2 .inner {
    flex-wrap: wrap; }
  #header2 .inner #wysiwyg_widgets_widget-4 {
    width: 100%; }
  #header2 .inner #wysiwyg_widgets_widget-5 {
    width: 100%; }
  #repair-section .inner .leftside {
    width: 100%; } }

@media only screen and (max-width: 600px) {
  .widget_nav_menu_top_row .menu .menu-item {
    width: 30%; }
  #footer1 .inner #blockgroup2 {
    justify-content: center; }
  #footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu {
    flex-wrap: wrap; }
  .widget_nav_menu_top_row .menu .menu-item a {
    text-align: center; }
  #footer1 .inner #blockgroup2 .nav_menu-9 {
    border-bottom: 1px dotted #0773b3;
    padding-bottom: 20px; }
  #footer1 .inner #blockgroup2 .social-wrapper {
    margin: 20px 0; } }

@media only screen and (max-width: 500px) {
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a {
    white-space: pre-wrap; }
  .outer.front-page .inner h1 {
    width: unset;
    min-width: unset; }
  #void-section .inner .void-textwrap {
    width: 100%; }
  #sealing-section .inner .sealing-image-container {
    flex-wrap: wrap; }
  #sealing-section .inner .sealing-image-container figure {
    width: 100%;
    height: 40vw;
    margin-bottom: 15px; }
  #sealing-section .inner .sealing-image-container figure:nth-of-type(1) {
    width: 100%; }
  #footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu {
    flex-wrap: wrap; }
  .widget_nav_menu_top_row .menu .menu-item {
    width: 100%; } }

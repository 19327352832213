/* ******************************************************************** */
/* ***  Widget - MENU Base CSS */
/* ******************************************************************** */

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

.widget_nav_menu {width: 100%; z-index: 600;  }
.widget_nav_menu > div {position: relative; z-index: 600; width: 100%; line-height: 0px;}
.widget_nav_menu .menu {display: flex; justify-content: space-between; align-items: center; list-style: none; padding: 0; width: 100%; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-padding-start: 0; margin:0}
.widget_nav_menu .menu .menu-item {margin: 0; padding:0; line-height: 0px; display: inline-block; line-height: 1em;}
.widget_nav_menu .menu .menu-item a {display: block; white-space: nowrap; transition: all 0.3s;}
.widget_nav_menu .menu .menu-item .sub-menu {position: relative; display: none; min-width: 200px; margin: 0; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-padding-start: 0; transition: all 0.3s; padding-left:0;}
.widget_nav_menu .menu .menu-item:hover .sub-menu {display: block; position: absolute; bottom: 0; transform: translateY(100%); padding-left: 0; border-bottom: 1px solid #000; padding-top: 21px;}
.widget_nav_menu .menu .menu-item .sub-menu .menu-item {display: block; position: relative; border-left: 1px solid #000; border-right: 1px solid #000; }
.widget_nav_menu .menu .menu-item .sub-menu:nth-of-type(2) {transform: translateY(100%) translateX(100%);} /* this is needed in the case that they hide a top level page */

/* TOP LEVEL Menu Adjustments */
.widget_nav_menu .menu > .menu-item > a {font-size:16px; padding: 10px 5px;  font-weight: 500; letter-spacing: 1.8px; text-transform: capitalize; line-height:1em; } /* top level */
.overrideCSS .widget_nav_menu .menu > .menu-item > a:hover {background: #B01532; color: #ffffff;}
.overrideCSS .widget_nav_menu .menu #menu-item-617 > a {background: #0773B3; color: #ffffff;}
.overrideCSS .widget_nav_menu .menu #menu-item-617 > a:hover {background: #B01532; color: #ffffff;}
/* Sub Menu Level Adjustments */
.widget_nav_menu .menu .sub-menu > .menu-item > a {font-size: 16px;  font-weight: 400; text-transform: none; padding: 10px 12px 10px; line-height:1em;} /* Sub Menu */

.nav-mobile {display: none; cursor: pointer; position: absolute; top: 0px; right: 0; height: 56px; width: 56px; font-size: 25px;  margin-right:0; z-index: 2000;} /* Mobile Navigation */
.nav-mobile .menuicon {width: 100%; height:100%; display:flex; justify-content:center; align-items: center; font-family: "dashicons";}
.nav-mobile .menuicon:before {content: "\f349";} 


.widget_nav_menu .menu .menu-item .sub-menu .sub-menu {display: none;}


/* ******************************************************************** */
/* ***  Widget - MENU - Top Row Base CSS */
/* ******************************************************************** */
.widget_nav_menu_top_row {width: 100%; display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap;}
.widget_nav_menu_top_row .centerbox {display: inline-block;}
.widget_nav_menu_top_row .menu {display: inline-block; list-style: none; padding: 0; -webkit-margin-before: 0; -webkit-margin-after: 0; -webkit-padding-start: 0; margin:0}
.widget_nav_menu_top_row .menu .menu-item {margin: 0; padding:0; line-height: 0px; display: inline-block; line-height: 1em;}
.widget_nav_menu_top_row .menu .menu-item a { font-size:17px; padding: 10px 5px;  font-weight: 500; text-transform: uppercase; line-height:1em; display: block;}

/* ******************************************************************** */
/* ***  Widget - MENU - Sub Menu Part CSS */
/* ******************************************************************** */
.widget_nav_sub_menu_part {display: inline-block;}
.widget_nav_sub_menu_part h2 { font-size: calc(12pt + .3vw); text-transform: capitalize;}
.widget_nav_sub_menu_part .menu {padding: 0; margin: 0;}
.widget_nav_sub_menu_part .menu .menu-item {list-style: none;}
.widget_nav_sub_menu_part .menu .menu-item a {transition: all 0.3s;}


@media only screen and (max-width: 1200px) {

  /* Mobile MENU tweaks NEW*/
  .widget_nav_menu {width: 100%;}
  .widget_nav_menu > div {height: 40px; padding: 0px 0; margin-top: 0px; }
  .widget_nav_menu .menu {display: none;}
  .widget_nav_menu .menu.nav-active {display: block; position: absolute; bottom: -19px; right: 0; transform: translateY(100%); border: 1px solid #000;}
  .widget_nav_menu .menu.nav-active .menu-item {display: block; margin-top: 0px;}
  .widget_nav_menu .menu.nav-active .menu-item .sub-menu {display: block; position: relative; transform: translateY(0); padding-top: 0; border-bottom: none;}
  .widget_nav_menu .menu .menu-item .sub-menu .menu-item {border: none;}
  /* TOP LEVEL Menu Adjustments */
  .overrideCSS .widget_nav_menu .menu > .menu-item.current-menu-parent > a,
  .widget_nav_menu .menu.nav-active > .menu-item > a {font-size: 18px;  font-weight: 400; text-transform: none; padding: 8px 12px 8px; line-height:1em;} /* top level */
  .overrideCSS .widget_nav_menu .menu #menu-item-617 > a {background-color: #ffffff; color: #040404;}
  /* Sub Menu Level Adjustments */
  .widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a {font-size: 18px; font-weight: 400; text-transform: none; padding: 8px 34px 8px 34px; line-height:1em; } /* Sub Menu */
  .widget_nav_menu .menu.nav-active .sub-menu .sub-menu .menu-item > a {padding: 8px 34px 8px 56px;} /* sub sub menu*/

  .nav-mobile {display: block; width: 40px; height: 40px;  padding: 0;} /* 3 bar menu block */
  .nav-mobile .menuicon:before {position: relative; left: 0px;}
  .nav {width: 100%; height: 56px; margin-left: 0px;}


}
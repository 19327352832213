/* EMPLOYMENT CSS */
/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */
 #allpositions {margin-bottom: 20px; }
.teaser-page .nth-rule-wrapper {width:100%;} /* this is a fix for IE11 */
.teaser-page .itembox {width: 100%; margin-bottom: 0; border: 1px solid #a19fa0; box-sizing: border-box; display:flex; justify-content:flex-start; align-items:center; flex-wrap: wrap;}

.teaser-page .itembox .col-one {width: 350px; padding:10px;box-sizing: border-box;  display:inline-block; border: 0;}
.teaser-page .itembox .col-one > div { line-height: 1.2em;}
.teaser-page .itembox .col-two {width:calc(100% - 350px); padding:10px; box-sizing: border-box; display:inline-block; border: 0;}
.teaser-page .itembox label {font-weight: bold;}
.teaser-page .itembox label:after {content: ':'; margin-right: 10px;}
.teaser-page .itembox .position-title { font-style: normal; font-size: calc(14pt + .28vw);  font-weight: 700; margin-bottom: 20px;}

.teaser-page .nth-rule-wrapper .itembox {width: 100%; box-sizing: border-box;}
.teaser-page .itembox.header-row {background: #445761; border-color: #445761; color: #ffffff;font-weight: 700; margin-bottom: 0; box-sizing: border-box; max-width: 100%; overflow: hidden;}
.teaser-page .itembox .col-two {border-left: 1px dashed #a19fa0;}


@media only screen and (max-width: 700px) {
  .teaser-page .itembox .position-title  {font-size: calc(14pt + 1.0vw); margin-top: 10px;}
  .teaser-page .itembox.header-row .col-two {display: none;}
  .teaser-page .itembox .col-one {width: 100%; margin-bottom: 10px;}
  .teaser-page .itembox.header-row .col-one {margin-bottom: 0;}
  .teaser-page .itembox .col-two {width: 100%; border-left: 0; padding-top: 0;  border-top: 1px dashed #e2e2e2;}
}  
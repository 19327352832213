#content.upcomingevents-page .inner {padding-bottom: 0;}

#upcomingevents .itemwrap {display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;}
#upcomingevents .itemwrap .topbox {line-height: 0;}
#upcomingevents .itemwrap .topbox img {width: 100%; height: auto;}
#upcomingevents .itemwrap .middlebox {background: #f9f9f9; border: 1px solid #cacaca; border-top: 0px; display: flex; justify-content: space-around; align-items: stretch; flex-wrap: wrap;  padding:10px 15px; box-sizing: border-box;}
#upcomingevents .itemwrap .middlebox .centerbox { width: 100%;display: flex; justify-content: flex-start;; align-items: stretch; flex-wrap: wrap;}
#upcomingevents .itemwrap .middlebox .leftside {color: #de1e07; font-weight: 600;font-size: calc(20px + 1.5vw); max-width: 70px; padding-right: 15px;; display: flex; justify-content: space-around;; align-items: center; flex-wrap: wrap;}
#upcomingevents .itemwrap .middlebox .rightside { display: flex; justify-content: flex-start; align-items: stretch; flex-wrap: wrap;}
#upcomingevents .itemwrap .middlebox .rightside .dateline {width: 100%; font-weight: 600;display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap;}
#upcomingevents .itemwrap .middlebox .rightside .dateline .month {display: inline-block; margin-right: 10px;}
#upcomingevents .itemwrap .middlebox .rightside .dateline .year {display: inline-block;}

#upcomingevents .itemwrap .middlebox .rightside .timeline {color: #6d6d6d; font-size: .75em; width: 100%; display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap; margin-top: 6px;}
#upcomingevents .itemwrap .middlebox .rightside .timeline .start {display: inline-block; margin-right: 10px;}
#upcomingevents .itemwrap .middlebox .rightside .timeline .end {display: inline-block;}


#upcomingevents .itemwrap .bottombox {background: #ffffff; border: 1px solid #cacaca; border-top: 0px; display: flex; justify-content: space-around; align-items: stretch; flex-wrap: wrap;  padding:20px 15px 15px; box-sizing: border-box;}
#upcomingevents .itemwrap .bottombox .centerbox {min-height: 230px; width: 100%; }
#upcomingevents .itemwrap .bottombox .centerbox .titlewrap {font-size: 1.2em; font-weight: 700; line-height: 1.3em;}
#upcomingevents .itemwrap .bottombox .centerbox .textwrap {color: #6d6d6d; font-size: .75em;}


#upcomingevents.fullpage .inner {padding-bottom: 0; margin-bottom: -20px;}
#upcomingevents.fullpage .itemwrap .middlebox {border-top: 1px solid #cacaca; align-items: center;}
#upcomingevents.fullpage .itemwrap .middlebox .leftside {max-width: 100%; width: 100%; padding: 0;}
#upcomingevents.fullpage .itemwrap .middlebox .rightside {width: 100%;}
#upcomingevents.fullpage .itemwrap .middlebox .rightside .dateline {justify-content: center; font-size: 1.5em; margin-top: 20px;}
#upcomingevents.fullpage .itemwrap .middlebox .rightside .timeline {justify-content: center; font-size: 1em; margin-top: 20px;}

@media only screen and (max-width:1000px) {
	#upcomingevents.fullpage .itemwrap .topbox {width: 50%;}
	#upcomingevents.fullpage .itemwrap .middlebox {width: 50%;}
}

@media only screen and (max-width:500px) {
	#upcomingevents.fullpage .itemwrap .topbox {width: 100%;}
	#upcomingevents.fullpage .itemwrap .middlebox {width: 100%;}	
}


@media only screen and (max-width:500px) {
	#upcomingevents .itemwrap .middlebox .leftside { font-size: calc(40px + 1.5vw)};
}

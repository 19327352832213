.search-form-widget {
	position: absolute;
	margin: 0;
	top: 0;
	right: 0;
	max-width: 200px;
}

.search-results {
	margin: 0 0 30px;
}

.search-result {
	margin: 30px 0 0 0;
	padding: 0 0 30px;
	border-bottom: 1px solid #e2e2e2;

	img {
		display: none;
	}

	span.standout {
		background: black;
		color: white;
		display: inline-block;
		padding: 2px 15px; 
		border-radius: 5px;
	}
}


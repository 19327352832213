/*  ------------------------------------------------------------------------------------  */
/*  -------------- START OF GENERIC CSS RULES ------------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

// @import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  
body {font-family: 'Poppins', sans-serif; font-size: 20px; position: relative; overflow: hidden; }
html body#tinymce {padding: 5px 15px !important; box-sizing: border-box;}

h1, h1 a {font-size: calc(16pt + .9vw); font-family: "Poppins",sans-serif; font-weight: 700; letter-spacing: 3.6px; line-height:1.2em; margin: 10px 0 10px; text-transform: none;}
h2, h2 a {font-size: calc(16pt + .8vw); font-family: "Poppins",sans-serif; font-weight: 700; letter-spacing: 3.6px; line-height:1.2em; margin: 10px 0; text-transform: none;}
h3, h3 a {font-size: calc(16pt + .7vw); font-family: "Poppins",sans-serif; font-weight: 700; letter-spacing: 3.6px; line-height:1.2em; margin: 10px 0; text-transform: none;}
h4, h4 a {font-size: calc(14pt + .6vw); font-family: "Poppins",sans-serif; font-weight: 700; letter-spacing: 3.6px; line-height:1.2em; margin: 10px 0;}
h5, h5 a {font-size: calc(14pt + .5vw); font-family: "Poppins",sans-serif; font-weight: 700; letter-spacing: 3.6px; line-height:1.2em; margin: 10px 0;}
h6, h6 a {font-size: calc(14pt + .4vw); font-family: "Poppins",sans-serif; font-weight: 700; letter-spacing: 3.6px; line-height:1.2em; margin: 10px 0;}

section:after {line-height: 0;} /*This was causing a 1px white space issue between 2 dark colors.  fix for core. */
.inner {width: 100%; max-width: 1600px; box-sizing: border-box; padding: 30px 2%; margin: 0 auto;}

p {margin: 20px 0; }
p,ul,ol,li {line-height: 1.5em;}
ul ul {list-style-type: circle; margin: 5px 0;}
strong, b {font-weight: 700;}
i {font-style: italic;}

img {max-width: 100%; height: auto;}
button {border: 0px; padding: 0; margin: 0; } 

a, a:link, a:visited { transition: all 0.3s;}
// a:hover, a:focus {color: #445761;}

/* BUTTONS ---------- */
	.form-item input.wpcf7-submit,
	.mce-content-body a.button[data-mce-selected], 
	.af-submit button.acf-button, 
	button.af-submit-button,
	a.button {font-weight: 400; font-size: 18px; padding: 10px 15px; width: inherit; border-width: 1px; border-style: solid;; border-radius: 0px; white-space: pre-wrap; line-height:1em; display:inline-block; text-transform: capitalize; box-sizing: border-box; transition: all 0.3s; letter-spacing: 1.8px;}

	/*      HOVER      */
	.form-item input.wpcf7-submit:hover,
	.mce-content-body a.button[data-mce-selected]:hover,
	.af-submit button.acf-button:hover,
	button.af-submit-button:hover,
	a.button:hover {box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);}

	a.button.small {padding: 2px 12px 4px; border-radius: 5px; text-transform: none;}

/* FORMS ---------- */
	form.acf-form {margin-top: 0px;}
	form.acf-form .acf-fields > .acf-field {border-top: none; padding: 10px 0;}
	form.acf-form .acf-form-submit {padding: 10px 0;}
	.form-item input.wpcf7-submit{-webkit-appearance: none; -moz-appearance: none;  appearance: none;  border-radius: 0;} /* This is a FIX for Safari on a CELL PHONE. for the button displays. */

/* WYSIWYG VIDEOS ---------- */
	/* automatically add a video Play button to a image thats linked as a popup youtube video. */
	a.popup-youtube {position: relative; display: inline-block; line-height: 0; overflow: hidden;}
	a.popup-youtube:after {content: "\f522"; font-family: "dashicons"; color: rgba(255,255,255,.7); text-shadow: 2px 2px 4px rgba(0,0,0,.7); font-size: calc(50px + 5vw); position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: space-around; align-items: center;}
	a.popup-youtube img {transition: all 0.3s;}
	a.popup-youtube:hover img {transform: scale3d(1.1,1.1,1);}

	.video-page a.popup-youtube:after {display: none;} /* so the play button doesnt double up on a video page that already has it. */

/* COMMON CSS CLASSES ---------- */
	.itembox {display: inline-block; box-sizing: border-box; transition: all 0.3s;}
	.centerbox {display: inline-block;}
	.imagewrapper {position: relative; overflow: hidden; line-height: 0px;} /* kill off the inherit line height thats on all divs causing 4px at the bottom of a div thats empty */
	.imagewrapper img {transition: all 0.3s;}
	.overlay {position: absolute; top: 0; left: 0; width: 100%; height: 100%; padding: 10px; box-sizing: border-box; display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap;}
	.leftside {width: 48%; display: inline-block;}
	.rightside {width: 48%; display: inline-block;}	

/* NOT NEEDED ANYMORE? ---------- */
	// img.alignright {float: right; padding: 0 0 20px 20px;}
	// a.alignright {float: right;  }
	// p.clearfix {clear: both; padding: 0; margin: 0; height: 1px; width: 100%; display: block;}



/*  ------------------------------------------------------------------------------------  */
/*  -------------- END OF GENERIC CSS RULES --------------------------------------------  */
/*  ------------------------------------------------------------------------------------  */


#header1 {padding: 0; position: relative; z-index: 10; width: 100%; border-bottom: 1px solid #0773B3;}
#header1 .inner {padding: 15px 2%;  display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;;}
#header1 .inner .widget_nav_menu {width: calc(73% - 250px);}
/**/
#header2 {}
#header2 h2 {font-size: calc(16pt + 0.5vw); line-height: 1.8em; }
#header2 .inner {padding: 15px 2%;}
#header2 .inner {display: flex; justify-content: space-between; align-items: center;}
#header2 .inner #wysiwyg_widgets_widget-4 {width: 54%;}
#header2 .inner #wysiwyg_widgets_widget-5 {width: 40%;}
#header2 .inner #wysiwyg_widgets_widget-5 p {line-height: 1.68em; font-weight: 300; font-size: 18px;}
/**/
#header3 {}
#header3 .inner {padding: 0 2%;}
.is-subpage #header3 .inner {margin: 0; max-width: unset; padding: 0;}

.all-content-wrapper {padding: 0; box-sizing: border-box;}
/**/
.outer.front-page .inner h1 {position:relative; width: 38%; min-width: 578px; color: #221F1F;}
.outer.front-page .inner h1::after {content: '';position: absolute; bottom: -20px; left: 0; width: 47%; height: 7px; background: #FBAC42;}
/**/
.is-homepage #stagger .itembox .leftside {width: 49%;}
.is-homepage #stagger .stagger-part:nth-of-type(1) h2{color: #B01532;}
.is-homepage #stagger .stagger-part:nth-of-type(2) a.button {background-color: #0773B3; border-color: #0773B3;}
.is-homepage #stagger .stagger-part:nth-of-type(2) a.button:hover {background-color: #445761; border-color: #445761;}
.is-homepage #stagger .itembox .rightside {width: 49%;}
.is-homepage #stagger .itembox .rightside p {margin: 20px 0;}
.is-homepage #stagger .itembox figure {height: 18vw; width: 100%; background-size: cover; background-repeat: no-repeat;}
.is-homepage .minislideshow, .is-homepage .minislideshow .slidebox {width: 100%;}
/**/
#void-section {border: 5px solid #FBAC42; color: #ffffff; position: relative; background-size: cover; background-repeat: no-repeat;}
#void-section::before {content: ''; position: absolute;z-index: 3; width: 100%; height: 100%; left: 0; top: 0; background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))}
#void-section .inner {padding-top: 20vw; padding-bottom: 50px; position: relative; z-index: 5;}
#void-section .inner .void-textwrap {width: 50%; }
#void-section .inner .void-textwrap h2 {color: #FBAC42; }
#void-section .inner .void-textwrap p {line-height: 1.8em; margin: 10px 0;}
#void-section .inner .void-textwrap a.button {background-color: #FBAC42; border-color: #FBAC42;}
#void-section .inner .void-textwrap a.button:hover {background-color: #445761; border-color: #445761;}
/**/
#sealing-section {}
#sealing-section .inner p {margin: 0;}
#sealing-section .inner a.button {margin: 20px 0; background-color: #0773B3; border-color: #0773B3; }
#sealing-section .inner a.button:hover {background-color: #445761; border-color: #445761;}
#sealing-section .inner .sealing-top-container {display: flex; justify-content: space-between; align-items: center;}
#sealing-section .inner .sealing-top-container .leftside {width: 43%;}
#sealing-section .inner .sealing-top-container .rightside {width: 55%;}
#sealing-section .inner .sealing-top-container h2 {line-height: 1.5em;}
#sealing-section .inner .sealing-image-container {display: flex; justify-content: space-between; align-items: center;}
#sealing-section .inner .sealing-image-container figure { width: 28%; height: 26vw; background-size: cover; background-repeat: no-repeat;}
#sealing-section .inner .sealing-image-container figure:nth-of-type(1) {width: 70%;}
/**/
#repair-section {position: relative; background-size: cover; background-attachment: fixed;}
#repair-section h2 {color: #ffffff;}
#repair-section .repair-overlay-color {position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 5;}
#repair-section .inner {position: relative; z-index: 10; color: #ffffff; display: flex; justify-content: space-between; align-items: center; padding: 100px 2%;}
#repair-section .inner .leftside {width: 42%;}
#repair-section .inner .leftside .imagewrapper {width: 100%;}
#repair-section .inner .rightside {width: 55%;}
#repair-section .inner .rightside p {letter-spacing: 1.8px;}
#repair-section .inner .rightside a.button {background-color: #ffffff; border-color: #ffffff; color: #B01532;}
#repair-section .inner .rightside a.button:hover {background-color: #445761; border-color: #445761; color: #ffffff;}
/**/

#footer1 {border-top: 1px solid #0773B3;}
#footer1 .inner {padding: 30px 2%; display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;}
#footer1 .inner #wysiwyg_widgets_widget-2 p {margin: 0;}
#footer1 .inner #wysiwyg_widgets_widget-3 p{margin: 5px 0; line-height: 1.3em;}
#footer1 .inner #wysiwyg_widgets_widget-3 p a {font-size: 17px; font-weight: 600; color: #221F1F; }
#footer1 .inner #wysiwyg_widgets_widget-3 p a:hover {color: #B01532;}
#footer1 .inner #blockgroup2 {align-self: stretch; display: flex; flex-wrap: wrap; align-content: space-between; justify-content: flex-end; width: 48%;}
#footer1 .inner #blockgroup2 .nav_menu-9 {margin-top: 20px;}
#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox {width: 100%;}
#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu{display: flex; justify-content: space-between; align-items: center; width: 100%;}
#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu #menu-item-559 {display: none;}
/**/
#footer2 {}
#footer2 .inner {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;}
#footer3 {}
#footer3 .inner {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap;}

#bfm-credits {background: #0c0c0c; color: #747b81; text-align: center; }
#bfm-credits .inner {font-size: .7em; line-height: 1.2em; padding: 15px 2%; }
#bfm-credits a {color: #747b81; }
#bfm-credits a:hover {color: #ffffff; }

.js-back-to-top {position: fixed;height: 50px;bottom: 0; right: 20px;  border-width: 1px; border-style: solid; border-radius: 20px 20px 0 0; transition: all 0.5s; transform: translateY(25px); z-index: 10000;}
.js-back-to-top:before {top: calc(50% - 5px); content: "\f342"; font-family: "dashicons";  font-size: 40px;}
.js-back-to-top:hover {transform: translateY(2px); cursor: pointer;}


@media only screen and (min-width:1921px) {
    body {font-size: calc(12.5pt + .2vw); }    

    .inner { max-width: 1877px;}

    .slideshow-banner-box {max-height: 70vh; overflow: hidden;}
    .slideshow-banner-box .itembox {width: 100%; max-height: 70vh;}
    .slideshow-banner-box .itembox figure.imagewrapper img {width: 100%; height: auto;}
} 

@media only screen and (max-width:1300px) {

}

@media only screen and (max-width:1100px) {
	#repair-section .inner {flex-wrap: wrap; justify-content: center;}
	#repair-section .inner .leftside {width: 63%; margin-bottom: 15px;}
	#repair-section .inner .rightside {width: 100%;}

	#footer1 .inner {padding-bottom: 15px;}
	#footer1 .inner #blockgroup2 {width: 100%;}
	#footer1 .inner #wysiwyg_widgets_widget-2 {width: 100%; display: flex; justify-content: center; border-bottom: 1px dotted #0773b3; padding-bottom: 10px;}
	#footer1 .inner #wysiwyg_widgets_widget-3 {width: 100%; border-top: 1px dotted #0773b3; padding-top: 10px; text-align: center;}
}

@media only screen and (max-width:1000px) {
	.is-homepage #stagger .itembox .leftside {width: 100%;}
	.is-homepage #stagger .itembox .rightside {width: 100%;}
	.is-homepage #stagger .stagger-part:nth-child(even) .rightside {order: 3;}
	.is-homepage #stagger .itembox figure {height: 49vw;}

	#void-section {background-position: center;}
	#void-section .inner .void-textwrap {width: 70%;}
}

@media only screen and (max-width:900px) {
	#header1 .inner .widget_nav_menu {width: calc(97% - 250px);}
}

@media only screen and (max-width:800px) {
	#sealing-section .inner .sealing-top-container {flex-wrap: wrap;}
	#sealing-section .inner .sealing-top-container .leftside {width: 100%;}
	#sealing-section .inner .sealing-top-container .rightside {width: 100%;}
}

@media only screen and (max-width:700px) {
	.widget_nav_menu .menu.nav-active { width: 95vw; }

	#header2 .inner {flex-wrap: wrap;}
	#header2 .inner #wysiwyg_widgets_widget-4 {width: 100%;}
	#header2 .inner #wysiwyg_widgets_widget-5 {width: 100%;}


	#repair-section .inner .leftside {width: 100%;}
}

@media only screen and (max-width: 600px){
	.widget_nav_menu_top_row .menu .menu-item { width: 30%; }
	#footer1 .inner #blockgroup2 {justify-content: center;}
	#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu {flex-wrap: wrap;}
	.widget_nav_menu_top_row .menu .menu-item a {text-align: center;}
	#footer1 .inner #blockgroup2 .nav_menu-9 {border-bottom: 1px dotted #0773b3; padding-bottom: 20px;}
	#footer1 .inner #blockgroup2 .social-wrapper {margin: 20px 0;}
}

@media only screen and (max-width:500px) {
	.widget_nav_menu .menu.nav-active .sub-menu > .menu-item > a {white-space: pre-wrap;}

	.outer.front-page .inner h1 {width: unset; min-width: unset;}

	#void-section .inner .void-textwrap {width: 100%;}

	#sealing-section .inner .sealing-image-container {flex-wrap: wrap;}
	#sealing-section .inner .sealing-image-container figure {width: 100%; height: 40vw; margin-bottom: 15px;}
	#sealing-section .inner .sealing-image-container figure:nth-of-type(1) {width: 100%;}

	#footer1 .inner #blockgroup2 .nav_menu-9 .centerbox > .menu {flex-wrap: wrap;}
	.widget_nav_menu_top_row .menu .menu-item { width: 100%; }
}
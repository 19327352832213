/*  ------------------------------------------------------------------------------------  */
/*  -------------- Default CSS Colors are now deployed through the ---------------------  */
/*  -------------- /src/css/html_head_css.php file  ------------------------------------  */
/*  ------------------------------------------------------------------------------------  */

.social-wrapper .itemwrapper {display: flex; justify-content: flex-start; align-items: center; flex-wrap: wrap;}
.social-wrapper .itembox {display: inline-block; margin: 3px 5px 3px 0;}
.social-wrapper .itembox:nth-last-child(1) {margin-right: 0;}
.social-wrapper .itembox a {text-decoration: none; display: flex; justify-content: space-around; align-items: center; transition: all 0.3s;}
.social-wrapper .icon {line-height: 0px; height: 40px; width:  40px; display: flex; justify-content: space-around; align-items: center;}
.social-wrapper .icon:after {font-family: "dashicons";  font-size: calc(40px * 0.7);}
.social-wrapper .icon.twitter svg { transition: all 0.3s;}
.social-wrapper .icon.tiktok svg { transition: all 0.3s; }
.social-wrapper .icon.tiktok svg path.cls-1  {  fill: #ffffff;}

/*  ------------------------------------------------------------------------------------  */
/*  -------------- TWITTER icon sizing note --------------------------------------------  */
/*  -- To change the icon size you need to alter the SVG size directly -----------------  */
/*  -- in the /inc/widgets/widget-social-icons.php file around line 61 -----------------  */
/*  ------------------------------------------------------------------------------------  */


.social-wrapper.round .itembox a {border-radius: 50%;}
.social-wrapper.square-rounded-corners .itembox a {border-radius: 5px;}
.social-wrapper.no-background .itembox a {background-color: transparent;}

.social-wrapper .icon.share:after {content: "\f237";}
.social-wrapper .icon.rss:after {content: "\f303";}
.social-wrapper .icon.email:after {content: "\f466";}
.social-wrapper .icon.networking:after {content: "\f325";}
.social-wrapper .icon.amazon:after {content: "\f162";}
.social-wrapper .icon.facebook:after {content: "\f305";}
.social-wrapper .icon.google:after {content: "\f18b";}
.social-wrapper .icon.instagram:after {content: "\f12d";}
.social-wrapper .icon.linkedin:after {content: "\f18d";}
.social-wrapper .icon.pinterest:after {content: "\f192";}
.social-wrapper .icon.podio:after {content: "\f19c";}
.social-wrapper .icon.reddit:after {content: "\f195";}
.social-wrapper .icon.spotify:after {content: "\f196";}
.social-wrapper .icon.twitch:after {content: "\f199";}
// .social-wrapper .icon.twitter:after {content: "\f301";} //deprecated old Twitter Icon
.social-wrapper .icon.whatsapp:after {content: "\f19a";}
.social-wrapper .icon.youtube:after {content: "\f19b";}

/* if a custom icon is needed.  You need to roll that icon into a FONT and then add a line like this followin gexample. */
/* .social-wrapper .icon.custom:after {content: "\e900"; font-family: "YourFontName";} */


